import React, { useState, useRef, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import "./style.css";
import exportFromJSON from "export-from-json";
import Calendar from "react-calendar";
import moment from "moment";
import { Table } from "react-bootstrap";
import Search from "../../../components/Search";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import { BASE_URL } from '../../../utils/constants.js';
import { element, func } from "prop-types";
import DataTable from "react-data-table-component";
import ExcelExport from "./ExcelExport.jsx";
import { sortRows, filterRows,Pagination } from '../../../components/paging/paging_helper.js'

export default function ReportBranchComponent(props) {

     const { to, from, setfrom, setto, search, setEmployeeId, employeeId, list, searchArray, setsearchArray, branchList, setbranchList } = props;
     const [toggleFrom, settoggleFrom] = useState(false);
     const [toggleto, settoggleto] = useState(false);
     const reportTemplateRef = useRef(null);
     const [activePage, setActivePage] = useState(1)
     const [sort, setSort] = useState({ order: 'asc', orderBy: 'city_name' });
     const [filters, setFilters] = useState({})
     const rowsPerPage = 10
    
     const filteredRows = filterRows(list?.data?.EmployeeRecordNH, filters)
     const sortedRows = sortRows(filteredRows, sort);
     const calculatedRows = sortedRows?.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)

     const count = sortedRows?.length
     const totalPages = Math.ceil(count / rowsPerPage)

    

     const handleSort = accessor => {
          setActivePage(1)
          setSort(prevSort => ({
               order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
               orderBy: accessor,
          }))
     }

     const handleSearch = (value, accessor) => {
          setActivePage(1)

          if (value) {
               setFilters((prevFilters) => ({
                    ...prevFilters,
                    [accessor]: value,
               }))
          } else {
               setFilters((prevFilters) => {
                    const updatedFilters = { ...prevFilters }
                    delete updatedFilters[accessor]

                    return updatedFilters
               })
          }
     }

    

     const tableColumn = [
          { accessor: 'branch_name', label: 'Branch Name', style: { width: 200 + 'px' } },
          { accessor: 'branch_code', label: 'Branch Code', style: { width: 200 + 'px' } },
          { accessor: 'city_name', label: 'City', style: { width: 200 + 'px' } },
          { accessor: 'state', label: 'State', style: { width: 200 + 'px' } },
          { accessor: 'RPH', label: 'RPH', style: { width: 200 + 'px' } },
          { accessor: 'SRPM', label: 'SRPM', style: { width: 200 + 'px' } },
          { accessor: 'RPM', label: 'RPM', style: { width: 200 + 'px' } },
          { accessor: 'audit_date', label: 'Review Date', style: { width: 200 + 'px' } },
          { accessor: 'name', label: 'Review By', style: { width: 200 + 'px' } },
          { accessor: 'role_name', label: 'Role', style: { width: 200 + 'px' } },
          { accessor: 'audit_type', label: 'Review Type', style: { width: 200 + 'px' } },
     ]

     const GenerateRecordsNew = () => {
          let employeeTable = [];
          let totalBranches = 0;
          let tableRow = [];

          // list?.data?.EmployeeRecordNH?.forEach((element, index) => {
          calculatedRows?.forEach((element, index) => {
               tableRow.push(<tr key={index}>
                    <td>{element.branch_name}</td>
                    <td>{element.branch_code}</td>
                    <td>{element.city_name}</td>
                    <td>{element.state}</td>
                    <td>{element.RPH}</td>

                    <td>{element.SRPM}</td>

                    <td>{element.RPM}</td>
                    <td>{element.audit_date} {element.audit_time?.replace("-", ":")}</td>
                    <td>{element.name}</td>
                    <td>{element.role_name}</td>
                    <td>{element.audit_type == 1 ? 'Online' : 'Physical'}</td>

               </tr>);
          });
          return tableRow;
          //console.log('uniqueNHRecord',employeeTable);


     };





     function capitalize(str) {
          return str.charAt(0).toUpperCase() + str.slice(1);
     }


     const handleEmployeeChange = (event) => {
          setEmployeeId(event.target.value);
     }

     const handleDownload = () => {
          const reportData = [];
          const newItem = {};
          props.list.map((item, index) => {
               Object.keys(item).map((key) => {
                    const newKey = key.replace(/_/g, " ");
                    const caps = newKey.split(" ").map(capitalize).join(" ");
                    newItem[caps] = item[key];
               });
               reportData.push(newItem);
          });
          const params = {
               data: reportData,
               fileName: "Report",
               exportType: "xls",
          };
          exportFromJSON(params);
     };


     return (
          <div>
               <Breadcrumb title="Report" parent="Report" />
               <div className="container-fluid">

                    <div className="row">
                         <div className="col">
                              <h4>Branch Report</h4>
                         </div>
                    </div>
                    <div className="row">
                         <div className="col">
                              <div className="card">
                                   <div className="row kpiMatrix">
                                        <div className="col">Start Date
                                             <br></br>
                                             <div>
                                                  <button
                                                       className="btn"
                                                       type="submit"
                                                       onClick={() => {
                                                            settoggleFrom(!toggleFrom);
                                                       }}
                                                  >
                                                       {from ? `${moment(from).format("DD-MM-YYYY")}` : "From"}
                                                  </button>
                                                  {toggleFrom ? <Calendar value={from} onChange={(e) => {
                                                       setfrom(e);
                                                       settoggleFrom(!toggleFrom)
                                                  }} /> : null}
                                             </div>
                                        </div>
                                        <div className="col">End Date
                                             <br></br>
                                             <div>
                                                  <button
                                                       className="btn"
                                                       type="submit"
                                                       onClick={() => {
                                                            settoggleto(!toggleto);
                                                       }}
                                                  >
                                                       {to ? `${moment(to).format("DD-MM-YYYY")}` : "To"}
                                                  </button>
                                                  {toggleto ? <Calendar value={to} onChange={(e) => {
                                                       setto(e);
                                                       settoggleto(!toggleto)
                                                  }} /> : null}
                                             </div>
                                        </div>
                                        {/* <div className="col">Employee Name
                                             <br></br>
                                             <select className="form-control" value={employeeId} onChange={handleEmployeeChange}>
                                                  <option value='' >--ALL--</option>
                                                  {
                                                       props.list ? props.list.data.EmployeeRecord?.map((rowData, index) => (
                                                            <option value={rowData.emp_id}>{rowData.name}</option>
                                                       )) : null
                                                  }
                                             </select>
                                        </div> */}

                                        <div className="col" style={{ margin: 'auto' }}>
                                             <button className="btn btn-primary" type="View" onClick={() => props.handleSelect(8)} >Generate Report</button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className='table-responsive'  >
                         {props.isLoading ? (
                              <center><h4>Loading...</h4></center>
                         ) : (
                              <div>

                                   <div className="row">
                                        <div className="col">

                                             <Table className='table_show' striped bordered hover id="table-to-xls">
                                                  <thead>
                                                       <tr className="table_head">

                                                            {tableColumn.map(column => {
                                                                 const sortIcon = () => {
                                                                      if (column.accessor === sort.orderBy) {
                                                                           if (sort.order === 'asc') {
                                                                                return '⬆️'
                                                                           }
                                                                           return '⬇️'
                                                                      } else {
                                                                           return '️↕️'
                                                                      }
                                                                 }
                                                                 return (
                                                                      <th key={column.accessor} style={column.style}>
                                                                           <a style={{ color: '#007bff', cursor: 'pointer' }} onClick={() => handleSort(column.accessor)}>{column.label}</a>
                                                                           {/* <button onClick={() => handleSort(column.accessor)}>{sortIcon()}</button> */}
                                                                      </th>
                                                                 )
                                                            })}

                                                       </tr>
                                                       <tr className="table_head">

                                                            {tableColumn.map(column => {

                                                                 return (
                                                                      <th>
                                                                           <input
                                                                                key={`${column.accessor}-search`}
                                                                                type="search"
                                                                                placeholder={`Search ${column.label}`}
                                                                                value={filters[column.accessor]}
                                                                                onChange={(event) => handleSearch(event.target.value, column.accessor)}
                                                                                className="form-control"
                                                                           />
                                                                      </th>
                                                                 )
                                                            })}

                                                       </tr>
                                                  </thead>
                                                  <tbody>
                                                       {GenerateRecordsNew()}

                                                  </tbody>
                                             </Table>
                                        </div>
                                   </div>
                              </div>

                         )}

                    </div>
                    <Pagination
                         activePage={activePage}
                         count={count}
                         rowsPerPage={rowsPerPage}
                         totalPages={totalPages}
                         setActivePage={setActivePage}
                    />
                    <div className="row">
                         <div className="col">
                              <ExcelExport csvHeader={props.header} csvData={list?.data?.EmployeeRecordNH} fileName="Branch_Report" ></ExcelExport>
                         </div>

                    </div>


               </div>
          </div>
     );
}

import React, { useState, useEffect } from 'react';
import LoginScreen from './component/login';
import { apiCall, setDefaultHeader } from '../../utils/httpClient';
import EndPoints from '../../utils/apiEndPoints'
// import { GlobalSpinnerContext } from '../../../component/Context/GlobalSpinnerContext'
import { AuthContext } from '../../components/Context/context';
import { UserContext } from '../../components/Context/UserContext';
import { useHistory } from 'react-router-dom';

const Login = () => {
     const { signIn } = React.useContext(AuthContext);
     const [userdata, setUserdata] = React.useContext(UserContext);
     const history = useHistory();
     const [email, setEmail] = useState('');
     const [password, setPassword] = useState('');
     const [errorMessage, setErrorMessage] = useState('')
     useEffect(() => {
          const authval = localStorage.getItem('AuthToken')
          if (authval) {

               if(JSON.parse(localStorage.getItem('userData')).emp_role_id == 5 || JSON.parse(localStorage.getItem('userData')).emp_role_id == 4){
                    history.push('dashboard/reportdashboard');
               }else{
                    history.push('dashboard');
               }
          }
     }, [])
     const loginAuth = async () => { 
          var params = {
               "email": email,
               "password": password,
          }
          const { data } = await apiCall('post', EndPoints.SIGNIN, params)
          
          if (data.status === 200) {
               signIn(data.token);
               setUserdata(data.data)
               localStorage.setItem('userData', JSON.stringify(data.data))
               if(data.data.emp_role_id == 4 || data.data.emp_role_id == 5){
                    history.push('dashboard/reportdashboard');
               }else{
                    history.push('dashboard');
               }
          } else if (data.status === 201) {
               setErrorMessage(data.message);
          } else {
               history.push('/');
          }

     }
     return (<LoginScreen
          loginAuth={loginAuth}
          setEmail={setEmail}
          setPassword={setPassword}
          errorMessage={errorMessage}
     />)

}

export default Login;
import React from "react";
import Alert from "../../../components/Alert";

export default function ChangePassword(props) {
  return (
    <div className="container-fluid">
          {
                props.message&&
                   <Alert message={props.message}/>
             
            }
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Current Password
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text" placeholder="Type Password"
                    value={props.oldPass}
                    onChange={(e) => props.setoldPass(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">New Password</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    value={props.newPass}
                    placeholder="*****"
                    onChange={(e) => props.setnewPass(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Confrom Password
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    value={props.confromPass}
                    placeholder="*****"
                    onChange={(e) => props.setconfromPass(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="col-sm-9 offset-sm-3">
            <button
              className="btn btn-primary mr-1"
              type="button"
              onClick={() => props.handleChangePassword()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../../components/common/breadcrumb";
import Search from "../../../components/Search";
import apiEndPoints from "../../../utils/apiEndPoints";
import { apiCall, setDefaultHeader, successToast } from "../../../utils/httpClient";
import './EmployeeList.css'

export default function EmployeList() {
  const [search, setsearch] = useState("");
  const [searchArray, setsearchArray] = useState();
  const [employeList, setEmployeeList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [empDetailsScreen, setEmpDetailsScreen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  useEffect(() => {
    GetEmpoyeList();
  }, []);
  
  const GetEmpoyeList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const param = {
      employee_roal_type: "",
    };
    const response = await apiCall(
      "POST",
      apiEndPoints.GETEMPLOYEELISTDATA,
      param
    );
    ;

    if (response.status === 200) {
      setEmployeeList(response.data.data);
      setsearchArray(response.data.data);
    } else {
      // errorToast(response.data.message);
    }
  };

  const columns = [
    {
      name: "Name of Employee",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    // {
    //   name: "Employee Code",
    //   selector: (row) => row.emp_code,
    //   sortable: true,
    // },
    {
      name: "Employee Type",
      selector: (row) =>
        row.emp_role_id == 3
          ? "RPM"
          : row.emp_role_id == 4
            ? "RPH"
            : row.emp_role_id == 5
              ? "SRPM"
              : row.emp_role_id == 2
                ? "NH"
                : "",
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        const statusValue = row.status;
        // console.log('row status', row);
        // Check if sortable and set appropriate display value
        let statusText;
        let statusColor;

      
        statusText = statusValue == 1 ? 'Active' : 'In-Active';
        statusColor = statusValue == 1 ? 'green !important' : 'red !important';
        console.log('statusColor', statusColor)
       

        return (
          <span className={`link-page ${statusValue === 1 ? "css-c-gren" : 'css-c-red'}`}>
            {statusText}
          </span>
        );
      },
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <span className="link-page" onClick={(e) => handleEmployeDetails(row)}>
              <i className="fas fa-eye"></i>
            </span>
          </div>
          <div style={{ paddingLeft: '5px', display: "flex", justifyContent: "center" }} >
            {/* <span className="link-page"
              to={`/dashboard/employe/${row.emp_id}`}
            > */}
            <span className="link-page" onClick={(e) => EditEmployeDetails(row)}>
              <i class="fas fa-edit"></i>
            </span>

            {/* <span className="link-page" onClick={(e) => EditEmployeDetails(row)}>
              <i className="fas fa-edit"></i>
            </span> */}
          </div>
          <div style={{ paddingLeft: '5px', display: "flex", justifyContent: "center" }}>
            <span className="link-page" onClick={(e) => handleresetDevicetoken(row)}>
              <i class="fas fa-history"></i>
            </span>
          </div>
        </div>

      ),
    },
  ];

  const EditEmployeDetails = (employee) => {
    setIsEditMode(true);
    setEmployeeDetails(employee);
    setEmpDetailsScreen(true);
  };


  const handleEmployeDetails = (employee) => {
    setIsEditMode(false);
    setEmployeeDetails(employee);
    setEmpDetailsScreen(true);
  };

  const handleresetDevicetoken = async (empdata) => {
    const params = {
      employee_id: empdata?.emp_id,
    };
    const response = await apiCall(
      "POST",
      apiEndPoints.RESETDEVICETOKEN,
      params
    );
    if (response.status === 200) {

      successToast(response.data.message);
      console.log('resetdevice response --', response);

    } else {
      // errorToast(response.data.message);
    }
  };



  const updateEmployeDetails = async (empData) => {
    setIsEditMode(true);
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = {
      emp_code: employeeDetails.emp_code,
      name: employeeDetails.name,
      phone: employeeDetails.phone,
      email: employeeDetails.email,
      emp_role_id: employeeDetails.emp_role_id,
      employee_id: employeeDetails.emp_id,
      status:employeeDetails.status
    };
    
    const response = await apiCall(
      "POST",
      apiEndPoints.EDITEMPLOYEE,
      params
    );

    // console.log("response update wala", response);
    if (response.status === 200) {
      setEmployeeList(response.data.data);
      setsearchArray(response.data.data);
      successToast(response.data.message);

    } else {
      // errorToast(response.data.message);
    }
  };

  return (
    <>
      {!empDetailsScreen ? (
        <div>
          <Breadcrumb title="Employee List" parent="Employee" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <div style={{ borderRadius: '15px', border: '0.2px solid lightgray', width: '870px' }}>
                      <Search
                        search={search}
                        setsearch={setsearch}
                        title={"Search By Employe Name"}
                      /></div>

                  </div>
                  <div className="card-block row">
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={
                            employeList &&
                            employeList.filter((data) => {
                              if (search == "") return searchArray;
                              else if (
                                data.name
                                  .toLowerCase()
                                  .includes(search.toLowerCase())
                              )
                                return data;
                            })
                          }
                          pagination
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // ------------------------------------ view Employement Details -------------------
        <div>
          <Breadcrumb title="Employe Details" parent="Employe" />
          <div className="container-fluid">
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">
                        Employe Code
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Code"
                          disabled={!isEditMode}
                          value={employeeDetails.emp_code}
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              emp_code: e.target.value,
                            })
                          }
                        />
                        {console.log('isEditMode', isEditMode)}
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">
                        Employe Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Name"
                          disabled={!isEditMode}
                          value={employeeDetails.name}
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">Email</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Email"
                          value={employeeDetails.email}
                          // disabled={!isEditMode}
                          disabled={true}
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group row mb-2">
                      <label className="col-sm-3 col-form-label">Phone</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          maxLength={10}
                          type="text"
                          placeholder="Phone"
                          value={employeeDetails.phone}
                          disabled={!isEditMode}
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              phone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Employe Role
                      </label>
                      <div className="col-sm-9">

                        {/* <input
                          className="form-control"
                          type="text"
                          placeholder="Employe Role"
                          disabled={!isEditMode}
                          value={
                            employeeDetails.emp_role_id == 3
                              ? "RMM"
                              : employeeDetails.emp_role_id == 4
                                ? "AH"
                                : employeeDetails.emp_role_id == 5
                                  ? "RMH"
                                  : employeeDetails.emp_role_id == 16
                                    ? "PM"
                                    : ""
                          }
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              emp_role_id: Number(e.target.value),
                            })
                          }
                        /> */}
                        <select
                          id=""
                          className="form-control"
                          value={employeeDetails.emp_role_id}
                          disabled={!isEditMode}
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              emp_role_id: Number(e.target.value),
                            })
                          }
                        >
                          <option value={2}>NH</option>
                          <option value={4}>RPH</option>
                          <option value={5}>SRPM</option>
                          <option value={3}>RPM</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Status
                      </label>
                      <div className="col-sm-9">
                        <select
                          id=""
                          className="form-control"
                          value={employeeDetails.status}
                          disabled={!isEditMode}
                          onChange={(e) =>
                            setEmployeeDetails({
                              ...employeeDetails,
                              status: Number(e.target.value),
                            })
                          }
                        >
                          <option value={1}>Active</option>
                          <option value={2}>In-Active</option>
                        </select>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="col-sm-9 offset-sm-3">
                        <button
                          className="btn btn-primary mr-1"
                          type="button"
                          // onClick={() => setEmpDetailsScreen(false)}
                          onClick={() => {
                            window.location.href = "/dashboard/employeList"
                          }}
                        >
                          Back
                        </button>
                        {isEditMode && <button className="btn btn-success"
                          onClick={(e) => {
                            e.preventDefault()
                            updateEmployeDetails()
                          }}
                        >Update</button>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

    </>
  );
}

import exportFromJSON from "export-from-json";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import apiEndPoints from "../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../utils/httpClient";
import ReportEmployeesComponent from "./component/ReportEmployeesComponent";

export default function ReportEmployees() {

     const today = new Date();
     let from_date = new Date(today.getFullYear(), today.getMonth(), 1);


     const [selected, setselected] = useState(8);
     const [list, setlist] = useState();
     const [isLoading, setisLoading] = useState(false);
     const [header, setheader] = useState();
     var [offset, setoffset] = useState(0);
     const [from, setfrom] = useState(from_date);
     const [to, setto] = useState(new Date());
     const typeRef = useRef(8);
     const [baseURL, setbaseURL] = useState();
     const [search, setsearch] = useState("");
     const [searchArray, setsearchArray] = useState();
     const [employeeId, setEmployeeId] = useState("");

     const handleSelect = (id) => {
          setselected(id);
          typeRef.current = id;
          handleAuditInfo("", 0);
     };

     useEffect(() => {
          handleAuditInfo("", 0);
     }, []);

     const handleAuditInfo = async (state, set) => {
          console.log("api Call");
          try {
               const userLoginData = JSON.parse(localStorage.getItem('userData'));

               setisLoading(true);
               const params = {
                    type: typeRef.current,
                    offset: set,
                    from_date: moment(from).format("YYYY-MM-DD"),
                    to_date: moment(to).format("YYYY-MM-DD"),
                    emp_role_id: userLoginData.emp_role_id,

                    emp_id: userLoginData.emp_id,
                    employeeId: employeeId
               };

               const userToken = localStorage.getItem("AuthToken");
               await setDefaultHeader("token", userToken);

               var response = []
              // params.from_date = moment(from_date).format("YYYY-MM-DD");
               var response = await apiCall(
                    "POST",
                    apiEndPoints.GETREPORTEMPLOYEES,
                    params
               );
               if (response.status === 200) {

                    setlist(response);

                    setheader(response.data.header);

                  
               }
               if (response.data.status === 201) {
                    setlist();
                    setheader();
               }
               if (response.data.status !== 201) {
                    if (state === "next") {
                         setoffset(set);
                    }
                    if (state === "prev") {
                         if (offset > 0) setoffset(set);
                    }
               }
               setisLoading(false);
          } catch (error) {
               setisLoading(false);
               console.log(error);
          }
     };
     return (
          <>
               <ReportEmployeesComponent
                    selected={selected}
                    handleSelect={handleSelect}
                   
                    isLoading={isLoading}
                    list={list}
                    header={header}
                    handleAuditInfo={handleAuditInfo}
                    offset={offset}
                    from={from}
                    setfrom={setfrom}
                    to={to}
                    setto={setto}
                    baseURL={baseURL}
                    search={search}
                    setsearch={setsearch}
                    searchArray={searchArray}
                    setsearchArray={setsearchArray}
                    employeeId={employeeId}
                    setEmployeeId={setEmployeeId}
                   
               />
          </>
     );
}

import React, { useState } from 'react'

export default function Alert({message}) {
    const [show, setShow] = useState(true);
    return (
        <div style={{ backgroundColor: "#004c8f", padding: 10, display: show ? "flex" : "none", justifyContent: "space-between", alignItems: "center", borderRadius: 10 }}>
            <h3 style={{ color: "#fff" }}>{message}</h3>
            <button style={{ backgroundColor: "#004c8e", color: "#fff", border: "none", padding: 10, fontSize: "1rem", cursor: "pointer" }}
                onClick={() => setShow(!show)}
            >X</button>
        </div>
    )
}

import exportFromJSON from "export-from-json";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import apiEndPoints from "../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../utils/httpClient";
import ReportDetailsComponent from "./component/ReportDetailsComponent";

export default function ReportDetails(props) {
     console.log("audit_id", props.match.params.id)
     const [audit_id, setAuditId] = useState(props.match.params.id);



     const [list, setlist] = useState();
     const [isLoading, setisLoading] = useState(false);

     useEffect(() => {
          handleAuditInfo()
      }, [])
     const handleAuditInfo = async (state, set) => {
          try {
               const userLoginData = JSON.parse(localStorage.getItem('userData'));

               setisLoading(true);
               const params = {
                    audit_id: audit_id
               };

               const userToken = localStorage.getItem("AuthToken");
               await setDefaultHeader("token", userToken);

               var response = [];

               var response = await apiCall(
                    "POST",
                    apiEndPoints.GETREPORTDETAILS,
                    params
               );


               if (response.status === 200) {
                    setlist(response);
                    console.log('response.data.header: ', response.data);
               }

               setisLoading(false);
          } catch (error) {
               setisLoading(false);
               console.log(error);
          }
     };
     return (
          <>
               <ReportDetailsComponent
                  
                    isLoading={isLoading}
                    list={list}

               />
          </>
     );
}

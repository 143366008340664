import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import { apiCall, setDefaultHeader, successToast } from "../../../utils/httpClient";
import apiEndPoints from "../../../utils/apiEndPoints";
export default function AddQuestion() {
  const [addQuestion, setAddQuestion] = useState({
    question_title: "",
    audit_question: "",
    question_type: "",
    score_range: 0,
    score_range_from: 0,
    score_range_to: 0,
    remark: "0",
    admin_assignee: 0,
    bm_actionable_assignee: 0,
    rmm_actionable_assignee: 0,
    atm_cordinator_assignee: 0,
    type: 0,
    atm_question_type: 0,
    actionsble_remark: "",
    yes_no_type: 0,
    set_range_2: 0,
    image_capture: 0,
    action_taken_no: 0,
    action_taken_on_yes: 0,
    count_type: 0,
    check_box_type: 0,
    check_box_value: ""
  });
  const [yes_no, setyes_no] = useState(0);
  const [questionList, setquestionList] = useState();
  const [searchArray, setsearchArray] = useState();
  const [search, setsearch] = useState("");
  const QuestionList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const response = await apiCall("GET", apiEndPoints.QUESTION_LIST);
    if (response.status === 200) {
      setsearchArray(response.data.data);
      setquestionList(response.data.data);
    }
  };
  useEffect(() => {
    QuestionList();
  }, []);
  const AddQuestionData = async (addQuestion) => {
    // console.log("data", addQuestion)
    try {
      const userToken = localStorage.getItem("AuthToken");
      setDefaultHeader("token", userToken);
      console.log("PARAMS:", addQuestion)
      const response = await apiCall(
        "POST",
        apiEndPoints.Add_QUESTION,
        addQuestion
      );
      console.log(response, "GETETETE");
      if (response.status === 200) {
        successToast(response?.data?.message)
        window.location.href = "/dashboard/questionlist";
      }
    } catch (error) {
      console.log(error);
    }

    // console.log(inputData);
  };
  const handleSearch = (text) => {
    // console.log("TEXT",text)
    setsearch(text);
    const val = searchArray.filter((question) => {
      return question.audit_question
        .toLowerCase()
        .includes(search.toLowerCase());
    });
    setquestionList(val);
  };
  const handleAddQuestion = (id) => {
    setAddQuestion({
      ...addQuestion,
      go_question_id: id,
      yes_no_type: yes_no,
    });
  };
  return (
    <>
      <Breadcrumb title="Add Question" parent="Question" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Title</label>
                      <div className="col-sm-9">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Type your title in Placeholder"
                          value={addQuestion.question_title}
                          onChange={(e) =>
                            setAddQuestion({
                              ...addQuestion,
                              question_title: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mb-0">
                      <label className="col-sm-3 col-form-label">Question</label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          rows="5"
                          cols="5"
                          placeholder="Type Question"
                          value={addQuestion.audit_question}
                          onChange={(e) =>
                            setAddQuestion({
                              ...addQuestion,
                              audit_question: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Question Type
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          id="group1"
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio1"
                              type="radio"
                              name="group1"
                              value="1"
                              checked={
                                addQuestion.question_type === "1" ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  question_type: e.target.value,
                                })
                              }
                            />
                            <label for="radio1">Yes/No</label>
                          </div>

                          <div class="radio radio-primary">
                            <input
                              id="radio2"
                              type="radio"
                              name="group1"
                              value="2"
                              checked={
                                addQuestion.question_type === "2" ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  question_type: e.target.value,
                                })
                              }
                            />
                            <label for="radio2">Count</label>
                          </div>

                          <div class="radio radio-primary">
                            <input
                              id="radio3"
                              type="radio"
                              name="group1"
                              value="5"
                              checked={
                                addQuestion.question_type === "5" ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  question_type: e.target.value,
                                })
                              }
                            />
                            <label for="radio3">YES/NO/NA</label>
                          </div>

                          {/* <div class="radio radio-primary">
                        <input
                          id="radio4"
                          type="radio"
                          name="group1"
                          value="4"
                          checked={
                            addQuestion.question_type === "4" ? true : false
                          }
                          onChange={(e) =>
                            setAddQuestion({
                              ...addQuestion,
                              question_type: e.target.value,
                            })
                          }
                        />
                        <label for="radio4">Check</label>
                      </div> */}
                        </fieldset>
                        {addQuestion.question_type === "1" && (
                          <div>
                            <fieldset
                              id="1.0"
                              style={{
                                display: "flex", width: "100px", alignItems: "center", justifyContent: "space-evenly"
                              }}
                            >
                              <div class="radio radio-primary">
                                <input
                                  id="1.0.0"
                                  type="radio"
                                  name="1.0"
                                  value="1"
                                  checked={yes_no === 1 ? true : false}
                                  onChange={(e) => {
                                    setAddQuestion({
                                      ...addQuestion,
                                      action_taken_on_yes: Number(e.target.value),
                                    });
                                    setyes_no(Number(e.target.value));
                                  }}
                                />
                                <label for="1.0.0">Yes</label>
                              </div>
                              <div class="radio radio-primary">
                                <input
                                  id="1.0.1"
                                  type="radio"
                                  name="1.0"
                                  value="2"
                                  checked={yes_no === 2 ? true : false}
                                  onChange={(e) => {
                                    setAddQuestion({
                                      ...addQuestion,
                                      action_taken_on_yes: Number(e.target.value),
                                    });
                                    setyes_no(Number(e.target.value));
                                  }}
                                />
                                <label for="1.0.1">No</label>
                              </div>
                            </fieldset>

                            {yes_no === 2 ? (
                              <div>
                                <div className="shadow">
                                  <h5>Next Question</h5>
                                  <input
                                    type="text"
                                    className="form-control shadow"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      handleSearch(e.target.value);
                                    }}
                                  />
                                  <div
                                    style={{ height: "200px", overflowY: "scroll" }}
                                  >
                                    {questionList &&
                                      questionList.map((row, index) => (
                                        <div
                                          style={{
                                            margin: "1rem 0rem",
                                            backgroundColor:
                                              addQuestion.go_question_id ===
                                                row.question_id
                                                ? "#303e5a"
                                                : "#fff",
                                            color:
                                              addQuestion.go_question_id ===
                                                row.question_id
                                                ? "#fff"
                                                : "#000",
                                            padding: 10,
                                          }}
                                          className="shadow"
                                          key={index}
                                          onClick={() => {
                                            handleAddQuestion(row.question_id);
                                          }}
                                        >
                                          <label>{row.audit_question}</label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div style={{ margin: "1rem 0rem" }}>
                                  <h5>Show Score</h5>
                                  <input
                                    type="number"
                                    className="form-control shadow"
                                    value={addQuestion.set_range_2}
                                    onChange={(e) =>
                                      setAddQuestion({
                                        ...addQuestion,
                                        set_range_2: parseInt(e.target.value),
                                      })
                                    }
                                  />
                                </div>

                                <div style={{ display: "flex" }}>
                                  <div>
                                    <h5>
                                      Action{" "}
                                      <p style={{ color: "gray" }}>
                                        (Selected item will not shown)
                                      </p>
                                    </h5>
                                    <fieldset id="1.1.0">
                                      <div class="radio radio-primary">
                                        <input
                                          id="1.1.1"
                                          type="radio"
                                          name="1.1.0"
                                          value="0"
                                          checked={
                                            addQuestion.action_taken_no == 0
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            // console.log(e.target.value)
                                            setAddQuestion({
                                              ...addQuestion,
                                              action_taken_no: Number(
                                                e.target.value
                                              ),
                                              action_taken_on_yes: Number(
                                                e.target.value
                                              ),
                                            })
                                          }
                                        />
                                        <label for="1.1.1">Default</label>
                                      </div>
                                      <div class="radio radio-primary">
                                        <input
                                          id="1.1.2"
                                          type="radio"
                                          name="1.1.0"
                                          value="1"
                                          checked={
                                            addQuestion.action_on_no === 1
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            setAddQuestion({
                                              ...addQuestion,
                                              action_on_no: Number(e.target.value),
                                              action_taken_no: 1,
                                              action_taken_on_yes: Number(
                                                e.target.value
                                              ),
                                            })
                                          }
                                        />
                                        <label for="1.1.2">Remark</label>
                                      </div>
                                      <div class="radio radio-primary">
                                        <input
                                          id="1.1.3"
                                          type="radio"
                                          name="1.1.0"
                                          value="2"
                                          checked={
                                            addQuestion.action_on_no === 2
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            setAddQuestion({
                                              ...addQuestion,
                                              action_on_no: Number(e.target.value),
                                              action_taken_no: 1,
                                              action_taken_on_yes: Number(
                                                e.target.value
                                              ),
                                            })
                                          }
                                        />
                                        <label for="1.1.3">Caputre Image</label>
                                      </div>

                                      <div class="radio radio-primary">
                                        <input
                                          id="1.1.4"
                                          type="radio"
                                          name="1.1.0"
                                          value="3"
                                          checked={
                                            addQuestion.action_on_no === 3
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            setAddQuestion({
                                              ...addQuestion,
                                              action_on_no: Number(e.target.value),
                                              action_taken_on_yes: Number(e.target.value),
                                              action_taken_no: 1,
                                            })
                                          }
                                        />
                                        <label for="1.1.4">Actionable</label>
                                      </div>
                                    </fieldset>
                                  </div>

                                  {/* <div>
                                  <h5>Remark</h5>
                                  <fieldset id="1.2.0">
                                    <div class="radio radio-primary">
                                      <input
                                        id="1.2.1"
                                        type="radio"
                                        name="1.2.0"
                                        value="1"
                                        checked={
                                          addQuestion.action_on_no === "1" ? true : false
                                        }
                                        onChange={(e) =>
                                          setAddQuestion({
                                            ...addQuestion,
                                            action_on_no: e.target.value,
                                          })
                                        }
                                      />
                                      <label for="1.2.1">Enable</label>
                                    </div>
                                    <div class="radio radio-primary">
                                      <input
                                        id="1.2.2"
                                        type="radio"
                                        name="1.2.0"
                                        value="0"
                                        checked={
                                          addQuestion.action_on_no === "0" ? true : false
                                        }
                                        onChange={(e) =>
                                          setAddQuestion({
                                            ...addQuestion,
                                            action_on_no: e.target.value,
                                          })
                                        }
                                      />
                                      <label for="1.2.2">Disable</label>
                                    </div>
                                  </fieldset>
                                </div>
                                <div>
                                  <h5>Image Capture</h5>
                                  <fieldset id="1.2.0">
                                    <div class="radio radio-primary">
                                      <input
                                        id="1.2.1"
                                        type="radio"
                                        name="1.2.0"
                                        value="1"
                                        checked={
                                          addQuestion.image_capture === 1 ? true : false
                                        }
                                        onChange={(e) =>
                                          setAddQuestion({
                                            ...addQuestion,
                                            image_capture: Number(e.target.value),
                                          })
                                        }
                                      />
                                      <label for="1.2.1">Enable</label>
                                    </div>
                                    <div class="radio radio-primary">
                                      <input
                                        id="1.2.2"
                                        type="radio"
                                        name="1.2.0"
                                        value="0"
                                        checked={
                                          addQuestion.image_capture === "0" ? true : false
                                        }
                                        onChange={(e) =>
                                          setAddQuestion({
                                            ...addQuestion,
                                            image_capture: Number(e.target.value),
                                          })
                                        }
                                      />
                                      <label for="1.2.2">Disable</label>
                                    </div>
                                  </fieldset>
                                </div> */}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="shadow">
                                  <h5>Next Question</h5>
                                  <input
                                    type="text"
                                    className="form-control shadow"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      handleSearch(e.target.value);
                                    }}
                                  />
                                  <div
                                    style={{ height: "200px", overflowY: "scroll" }}
                                  >
                                    {questionList &&
                                      questionList.map((row, index) => (
                                        <div
                                          style={{
                                            margin: "1rem 0rem",
                                            backgroundColor:
                                              addQuestion.go_question_id ===
                                                row.question_id
                                                ? "#303e5a"
                                                : "#fff",
                                            color:
                                              addQuestion.go_question_id ===
                                                row.question_id
                                                ? "#fff"
                                                : "#000",
                                            padding: 10,
                                          }}
                                          className="shadow"
                                          key={index}
                                          onClick={() => {
                                            handleAddQuestion(row.question_id);
                                          }}
                                        >
                                          <label>{row.audit_question}</label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                                <div style={{ margin: "1rem 0rem" }}>
                                  <h5>Show Score</h5>
                                  <input
                                    type="number"
                                    className="form-control shadow"
                                    value={addQuestion.set_range_1}
                                    onChange={(e) =>
                                      setAddQuestion({
                                        ...addQuestion,
                                        set_range_1: parseInt(e.target.value),
                                      })
                                    }
                                  />
                                </div>

                                <div style={{ display: "flex" }}>
                                  <div>
                                    <h5>
                                      Action{" "}
                                      <p style={{ color: "gray" }}>
                                        (Selected item will not shown)
                                      </p>
                                    </h5>
                                    <fieldset id="1.2.0">
                                      <div class="radio radio-primary">
                                        <input
                                          id="1.2.1"
                                          type="radio"
                                          name="1.2.0"
                                          value="0"
                                          checked={
                                            addQuestion.action_taken_on_yes == 0
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            // console.log(e.target.value)
                                            setAddQuestion({
                                              ...addQuestion,
                                              action_taken_on_yes: Number(
                                                e.target.value
                                              ),
                                              action_taken_no: 0,
                                            })
                                          }
                                        />
                                        <label for="1.2.1">Default</label>
                                      </div>
                                      <div class="radio radio-primary">
                                        <input
                                          id="1.2.2"
                                          type="radio"
                                          name="1.2.0"
                                          value="1"
                                          checked={
                                            addQuestion.action_on_yes === 1
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            setAddQuestion({
                                              ...addQuestion,
                                              action_on_yes: Number(e.target.value),
                                              action_taken_on_yes: 1,
                                              action_taken_no: 0,
                                            })
                                          }
                                        />
                                        <label for="1.2.2">Actionable</label>
                                      </div>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        {addQuestion.question_type === "2" && (
                          <div>
                            <h5>Action</h5>
                            <div>
                              <fieldset id="1.2.0">
                                <div class="radio radio-primary">
                                  <input
                                    id="1.2.1"
                                    type="radio"
                                    name="1.2.0"
                                    value="0"
                                    checked={
                                      addQuestion.count_type == 0 ? true : false
                                    }
                                    onChange={(e) =>
                                      // console.log(e.target.value)
                                      setAddQuestion({
                                        ...addQuestion,
                                        count_type: Number(e.target.value),
                                      })
                                    }
                                  />
                                  <label for="1.2.1">Default</label>
                                </div>
                                <div class="radio radio-primary">
                                  <input
                                    id="1.2.2"
                                    type="radio"
                                    name="1.2.0"
                                    value="2"
                                    checked={
                                      addQuestion.count_type === 2 ? true : false
                                    }
                                    onChange={(e) =>
                                      setAddQuestion({
                                        ...addQuestion,
                                        count_type: Number(e.target.value),
                                      })
                                    }
                                  />
                                  <label for="1.2.2">
                                    {"Greater then 0 (Previous Qty>0)"}
                                  </label>
                                </div>
                                <div class="radio radio-primary">
                                  <input
                                    id="1.2.3"
                                    type="radio"
                                    name="1.2.0"
                                    value="1"
                                    checked={
                                      addQuestion.count_type === 1 ? true : false
                                    }
                                    onChange={(e) =>
                                      setAddQuestion({
                                        ...addQuestion,
                                        count_type: Number(e.target.value),
                                      })
                                    }
                                  />
                                  <label for="1.2.3">{"Previous Qty > 0"}</label>
                                </div>
                              </fieldset>
                              {addQuestion.count_type === 2 && (
                                <div className="shadow">
                                  <h5>Previous Question</h5>
                                  <input
                                    type="text"
                                    className="form-control shadow"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      handleSearch(e.target.value);
                                    }}
                                  />
                                  <div
                                    style={{ height: "200px", overflowY: "scroll" }}
                                  >
                                    {questionList &&
                                      questionList.map((row, index) => (
                                        <div
                                          style={{
                                            margin: "1rem 0rem",
                                            backgroundColor:
                                              addQuestion.count_previous_question_id ===
                                                row.question_id
                                                ? "#303e5a"
                                                : "#fff",
                                            color:
                                              addQuestion.count_previous_question_id ===
                                                row.question_id
                                                ? "#fff"
                                                : "#000",
                                            padding: 10,
                                          }}
                                          className="shadow"
                                          key={index}
                                          onClick={() => {
                                            setAddQuestion({
                                              ...addQuestion,
                                              count_previous_question_id:
                                                row.question_id,
                                            });
                                          }}
                                        >
                                          <label>{row.audit_question}</label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                              {addQuestion.count_type === 1 && (
                                <div className="shadow">
                                  <h5>Previous Question</h5>
                                  <input
                                    type="text"
                                    className="form-control shadow"
                                    placeholder="Search"
                                    value={search}
                                    onChange={(e) => {
                                      handleSearch(e.target.value);
                                    }}
                                  />
                                  <div
                                    style={{ height: "200px", overflowY: "scroll" }}
                                  >
                                    {questionList &&
                                      questionList.map((row, index) => (
                                        <div
                                          style={{
                                            margin: "1rem 0rem",
                                            backgroundColor:
                                              addQuestion.go_to_count_question_id ===
                                                row.question_id
                                                ? "#303e5a"
                                                : "#fff",
                                            color:
                                              addQuestion.go_to_count_question_id ===
                                                row.question_id
                                                ? "#fff"
                                                : "#000",
                                            padding: 10,
                                          }}
                                          className="shadow"
                                          key={index}
                                          onClick={() => {
                                            setAddQuestion({
                                              ...addQuestion,
                                              go_to_count_question_id:
                                                row.question_id,
                                            });
                                          }}
                                        >
                                          <label>{row.audit_question}</label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Check Type</label>
                      <div className="col-sm-9">
                        <fieldset
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                          id="checkType"
                        >
                          <div class="radio radio-primary">
                            <input
                              id="checkType1"
                              type="radio"
                              name="checkType"
                              value="1"
                              checked={
                                addQuestion.check_box_type === 1 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  check_box_type: Number(e.target.value),
                                })
                              }
                            />
                            <label for="checkType1">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="checkType2"
                              type="radio"
                              name="checkType"
                              value="0"
                              checked={
                                addQuestion.check_box_type === 0 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  check_box_type: Number(e.target.value),
                                })
                              }
                            />
                            <label for="checkType2">No</label>
                          </div>
                        </fieldset>
                        {
                          addQuestion.check_box_type === 1 && (
                            <input
                              className="form-control"
                              type="text"
                              placeholder="One,Two,Three"
                              value={addQuestion.check_box_value}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  check_box_value: e.target.value,
                                })
                              }
                              required
                            />
                          )
                        }
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Score Range</label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group2"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio5"
                              type="radio"
                              name="group2"
                              value="0"
                              checked={addQuestion.score_range === 0 ? true : false}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  score_range: parseInt(e.target.value),
                                })
                              }
                            />
                            <label for="radio5">No</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio6"
                              type="radio"
                              name="group2"
                              value="1"
                              checked={addQuestion.score_range === 1 ? true : false}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  score_range: parseInt(e.target.value),
                                })
                              }
                            />
                            <label for="radio6">Yes</label>
                          </div>
                        </fieldset>
                        {addQuestion.score_range === 1 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "80%",
                            }}
                          >
                            <div>
                              <label>Range form </label>
                              <input
                                type="number"
                                className="form-control shadow"
                                value={addQuestion.score_range_from}
                                onChange={(e) =>
                                  setAddQuestion({
                                    ...addQuestion,
                                    score_range_from: parseInt(e.target.value),
                                  })
                                }
                              />
                            </div>
                            <div>
                              <label>Range to </label>
                              <input
                                type="number"
                                className="form-control shadow"
                                value={addQuestion.score_range_to}
                                onChange={(e) =>
                                  setAddQuestion({
                                    ...addQuestion,
                                    score_range_to: parseInt(e.target.value),
                                  })
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Remark</label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group3"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio7"
                              type="radio"
                              name="group3"
                              value="1"
                              checked={addQuestion.remark === "1" ? true : false}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  remark: e.target.value,
                                })
                              }
                            />
                            <label for="radio7">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio8"
                              type="radio"
                              name="group3"
                              value="0"
                              checked={addQuestion.remark === "0" ? true : false}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  remark: e.target.value,
                                })
                              }
                            />
                            <label for="radio8">No</label>
                          </div>
                        </fieldset>
                        {addQuestion.remark === "1" ? (
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Type your remark"
                            value={addQuestion.actionsble_remark}
                            onChange={(e) =>
                              setAddQuestion({
                                ...addQuestion,
                                actionsble_remark: e.target.value,
                              })
                            }
                          />
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Image Capture
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          id="200"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="22"
                              type="radio"
                              name="200"
                              value="1"
                              checked={
                                addQuestion.image_capture === 1 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  image_capture: Number(e.target.value),
                                })
                              }
                            />
                            <label for="22">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="21"
                              type="radio"
                              name="200"
                              value="0"
                              checked={
                                addQuestion.image_capture === 0 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  image_capture: Number(e.target.value),
                                })
                              }
                            />
                            <label for="21">No</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Branch Manager Assign
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group4"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio22"
                              type="radio"
                              name="group4"
                              value="1"
                              checked={
                                addQuestion.bm_actionable_assignee === 1
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  bm_actionable_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio22">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio21"
                              type="radio"
                              name="group4"
                              value="0"
                              checked={
                                addQuestion.bm_actionable_assignee === 0
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  bm_actionable_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio21">No</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        RMM Action Assign
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group7"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio13"
                              type="radio"
                              name="group7"
                              value="1"
                              checked={
                                addQuestion.rmm_actionable_assignee === 1
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  rmm_actionable_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio13">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio14"
                              type="radio"
                              name="group7"
                              value="0"
                              checked={
                                addQuestion.rmm_actionable_assignee === 0
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  rmm_actionable_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio14">No</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        ATM coordinator Assign
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group17"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio11"
                              type="radio"
                              name="group17"
                              value="1"
                              checked={
                                addQuestion.atm_cordinator_assignee == 1
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  atm_cordinator_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio11">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio10"
                              type="radio"
                              name="group17"
                              value="0"
                              checked={
                                addQuestion.atm_cordinator_assignee == 0
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  atm_cordinator_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio10">No</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Admin Assign
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group8"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio16"
                              type="radio"
                              name="group8"
                              value="1"
                              checked={
                                addQuestion.admin_assignee == 1 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  admin_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio16">Yes</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio17"
                              type="radio"
                              name="group8"
                              value="0"
                              checked={
                                addQuestion.admin_assignee == 0 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  admin_assignee: Number(e.target.value),
                                })
                              }
                            />
                            <label for="radio17">No</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Branch type</label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group5"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio18"
                              type="radio"
                              name="group5"
                              value="0"
                              checked={addQuestion.type === 0 ? true : false}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  type: parseInt(e.target.value),
                                })
                              }
                            />
                            <label for="radio18">Old</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="19"
                              type="radio"
                              name="group5"
                              value="1"
                              checked={addQuestion.type === 1 ? true : false}
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  type: parseInt(e.target.value),
                                })
                              }
                            />
                            <label for="19">New</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        ATM Question Type
                      </label>
                      <div className="col-sm-9">
                        <fieldset
                          id="group15"
                          style={{
                            display: "flex",
                            width: 100,
                            justifyContent: "space-between",
                          }}
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radio19"
                              type="radio"
                              name="group15"
                              value="0"
                              checked={
                                addQuestion.atm_question_type === 0 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  atm_question_type: parseInt(e.target.value),
                                })
                              }
                            />
                            <label for="radio19">No</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radio20"
                              type="radio"
                              name="group15"
                              value="1"
                              checked={
                                addQuestion.atm_question_type === 1 ? true : false
                              }
                              onChange={(e) =>
                                setAddQuestion({
                                  ...addQuestion,
                                  atm_question_type: parseInt(e.target.value),
                                })
                              }
                            />
                            <label for="radio20">Yes</label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <div className="col-sm-9 offset-sm-3">
                  <button
                    className="btn btn-primary mr-1"
                    type="button"
                    onClick={() => AddQuestionData(addQuestion)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form></div></div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";
import apiEndPoints from "../../../utils/apiEndPoints";
import "./addBranchEmp.css";

const AddBranchEmployee = () => {
  const [inputData, setInputData] = useState({
    city_id: "",
    branch_id: "",
    employee_id: "",
    employee_roal_type: "",
  });

  //   ----------------------------------------- City List -----------------------------------
  const [cityList, setcityList] = useState([]);
  const [CityserviceListShow, setCityServiceListShow] = useState(false);
  const [filterCityList, setFilterCityList] = useState([]);
  const [Loader, setLoader] = useState(false);

  const handleCityListInput = (e) => {
    console.log("e", e);
    setCityServiceListShow(true);
    var City_List = cityList.filter((curElem) => {
      if (e == "") {
        return curElem;
      } else if (
        curElem.city_name
          .toString()
          .toLowerCase()
          .includes(e.toString().toLowerCase())
      ) {
        return curElem;
      }
    });
    console.log("curElem", City_List);
    setFilterCityList(City_List);
  };
  // console.log("filterCityList", filterCityList);
  // console.log("inputData", inputData);

  function handleSelectCItyService(item) {
    console.log("itemmmmmmmm", item);
    document.getElementById("serviceCitySearch").value = item;
    setCityServiceListShow(false);
    // setEmployeeList([]);
  }


  useEffect(() => {
    CityList();
    DisplayCityList();
  }, []);

  useEffect(() => {
    BranchList();
  }, [inputData.city_id]);

  useEffect(() => {
    EmployeeList();
  }, [inputData.employee_roal_type]);

  var CityName = [];
  const CityList = async () => {
    setLoader(true)
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = { type: 1 };
    const response = await apiCall("POST", apiEndPoints.City_List, params);
    // console.log("GETCITYLISTDATA data  citi list ===>", response);
    if (response.status === 200) {
      setcityList(response.data.data);
      setLoader(false)
    } else {
      setLoader(false)
    }
  };

  const DisplayCityList = async () => {
    CityName = console.log("cityname", CityName);
  };

  //   ------------------------------------- Branch List -----------------------------------

  const [branchList, setBranchList] = useState([]);

  var BranchName = [];

  const BranchList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = { city_id: inputData.city_id };
    const response = await apiCall("POST", apiEndPoints.GETBRANCHNAME, params);
    console.log("GETBRANCHNAME data  mm ===>", response);
    if (response.status === 200) {
      setBranchList(response.data.data);
      console.log('response of GETBRANCHNAME', response?.data)
    }
  };

  // ------------------------------------------ Employee List Data ----------------------------------

  const [employeeList, setEmployeeList] = useState([]);
  const [serviceListShow, setServiceListShow] = useState(false);
  const [filterEmpList, setFilterEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const EmployeeList = async () => {
    setIsLoading(true);
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = { employee_roal_type: inputData.employee_roal_type };
    const response = await apiCall(
      "POST",
      apiEndPoints.GETEMPLOYEELISTDATA,
      params
    );
    console.log("GETEMPLOYEELISTDATA data  emplye list ===>", response);
    if (response.status === 200) {
      setEmployeeList(response.data.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  function handleSelectService(item) {
    document.getElementById("serviceSearch").value = item;
    setServiceListShow(false);
    // setEmployeeList([]);
  }
  const handleEmplyeeListInput = (e) => {
    // console.log("e", e);
    setServiceListShow(true);
    var emp_list = employeeList.filter((curElem) => {
      if (e == "") {
        return curElem;
      } else if (
        curElem.name
          .toString()
          .toLowerCase()
          .includes(e.toString().toLowerCase())
      ) {
        return curElem;
      }
    });
    console.log("curElem", emp_list);
    setFilterEmpList(emp_list);
  };

  // console.log("filterEmpList", filterEmpList);
  // console.log("inputData", inputData);

  //   -------------------------------------------- form validation -------------------------
  const [formErrors, setFormErrors] = useState({});
  function emailValidationChk() {
    let formErrors = {};
    let formIsValid = true;

    if (!inputData.city_id) {
      formIsValid = false;
      formErrors["city_id"] = "Select City";
    }
    if (!inputData.branch_id) {
      formIsValid = false;
      formErrors["branch_id"] = "Select Branch";
    }
    if (!inputData.employee_id) {
      formIsValid = false;
      formErrors["employee_id"] = "Select Employee";
    }
    if (!inputData.employee_roal_type) {
      formIsValid = false;
      formErrors["employee_roal_type"] = "Select Empolyee Type";
    }
    setFormErrors(formErrors);
    return formIsValid;
  }

  // ------------------------------------------ Submit Data ----------------------------------
  const employeeDataInsert = async () => {
    if (emailValidationChk()) {
      // console.log("inputData", inputData);
      try {
        const userToken = localStorage.getItem("AuthToken");
        setDefaultHeader("token", userToken);
        const response = await apiCall(
          "POST",
          apiEndPoints.UPDATEEMPLOYEEINBRANCH,
          inputData
        );
        console.log(response, "UPDATEEMPLOYEEINBRANCH ====>");
        if (response.status === 200) {
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Breadcrumb title="Branch Employee" parent="Add Branch Employee" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group row mb-1">
                      <label className="col-sm-3 col-form-label">City</label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <div className="abs">
                            <input
                              type="text"
                              className="form-control"
                              id="serviceCitySearch"
                              onChange={(e) => handleCityListInput(e.target.value)}
                              placeholder={
                                Loader == true
                                  ? "Loading.........."
                                  : "Type to Search City"
                              }
                              autoComplete="off"
                            />
                          </div>
                          <div>
                            <div>
                              {/* {console.log("CityList", cityList)} */}
                              {CityserviceListShow &&
                                cityList &&
                                cityList.length > 0 ? (
                                <ul className="emp_list_menu">
                                  {filterCityList.map((item, i) => {
                                    return (
                                      <li
                                        onClick={() => {
                                          setInputData({
                                            ...inputData,
                                            city_id: item.city_id,
                                          });
                                          handleSelectCItyService(item.city_name);
                                        }}
                                      >
                                        <div
                                          className="emp_li"
                                          style={{
                                            // display: "flex",
                                            // justifyContent: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "15%",
                                              marginLeft: "5px",
                                            }}
                                            className="empl_list"
                                          >
                                            <span>{item.city_name}</span>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }, [])}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",
                            }}
                          >
                            {!inputData.city_id &&
                              formErrors &&
                              formErrors.city_id
                              ? formErrors.city_id
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-1">
                      <label className="col-sm-3 col-form-label">Branch</label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <div className="abs">
                            <select
                              id=""
                              className="form-control"
                              value={inputData.branch_id}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_id: Number(e.target.value),
                                })
                              }
                            >
                              <option value="">Select Branch-</option>
                              {inputData.city_id &&
                                branchList &&
                                branchList.map((value) => (
                                  < option value={value.branch_id} >
                                    {/* {console.log('chacha', value)} */}
                                    {value.branch_name}
                                  </option>
                                ))}
                            </select>
                            <span class="tooltiptext">
                              First Select City to select Branch List
                            </span>
                          </div>
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",
                            }}
                          >
                            {!inputData.branch_id &&
                              formErrors &&
                              formErrors.branch_id
                              ? formErrors.branch_id
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row mb-1">
                      <label className="col-sm-3 col-form-label">
                        Employee Type
                      </label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <select
                            id=""
                            className="form-control"
                            value={inputData.employee_roal_type}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                employee_roal_type: Number(e.target.value),
                              })
                            }
                          >
                            <option value="">Select Employee Type-</option>
                            <option value="5">RMH</option>
                            <option value="4">AH</option>
                            <option value="3">RMM</option>
                          </select>
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",
                            }}
                          >
                            {!inputData.employee_roal_type &&
                              formErrors &&
                              formErrors.employee_roal_type
                              ? formErrors.employee_roal_type
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-1">
                  <label className="col-sm-3 col-form-label">Employee</label>
                  <div className="col-sm-9">
                    <div className="col-sm-9">
                      <div className="abs">
                        <input
                          type="text"
                          className="form-control"
                          id="serviceSearch"
                          onChange={(e) => handleEmplyeeListInput(e.target.value)}
                          placeholder={
                            isLoading == true
                              ? "Loading.........."
                              : "Select Employee"
                          }
                          autoComplete="off"
                        />
                        <span class="tooltiptext">
                          First Select Employee Type and then Search
                        </span>
                      </div>
                      <div>
                        <div>
                          {console.log("employeeList", employeeList)}
                          {serviceListShow &&
                            employeeList &&
                            employeeList.length > 0 ? (
                            <ul className="emp_list_menu">
                              {filterEmpList.map((item, i) => {
                                return (
                                  <li
                                    onClick={() => {
                                      setInputData({
                                        ...inputData,
                                        employee_id: item.emp_id,
                                      });
                                      handleSelectService(item.name);
                                    }}
                                  >
                                    <div
                                      className="emp_li"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "20%",
                                          marginLeft: "5px",
                                        }}
                                        className="empl_list"
                                      >
                                        <span>{item.emp_code}</span>
                                      </div>
                                      <div
                                        style={{
                                          width: "30%",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <span>{item.name}</span>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          marginTop: "5px",
                                        }}
                                      >
                                        <span>{item.email}</span>
                                      </div>
                                    </div>
                                  </li>
                                );
                              }, [])}
                            </ul>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: "10px",
                        }}
                      >
                        {!inputData.employee_id &&
                          formErrors &&
                          formErrors.employee_id
                          ? formErrors.employee_id
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer">
                <div className="col-sm-9 offset-sm-3">
                  <button
                    className="btn btn-primary mr-1"
                    type="button"
                    onClick={() => employeeDataInsert()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form >
          </div>
        </div>
      </div >
    </>
  );
};

export default AddBranchEmployee;

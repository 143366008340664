import React, { useEffect, useState } from "react";
import QuestionComponent from "./component/QuestionComponent";
import { apiCall, setDefaultHeader } from "../../utils/httpClient";
import apiEndPoints from "../../utils/apiEndPoints";
import { Redirect } from "react-router";
export default function Question(props) {
  console.log(props, "props");
  const [isLoading, setisLoading] = useState(false);
  const [yes_no, setyes_no] = useState(0);
  const [question, setquestion] = useState({
    question_title: "",
    audit_question: "",
    question_type: "",
    score_range: 0,
    score_range_from: 0,
    score_range_to: 0,
    remark: "0",
    admin_assignee: 0,
    bm_actionable_assignee: 0,
    rmm_actionable_assignee: 0,
    atm_cordinator_assignee: 0,
    type: 0,
    atm_question_type: 0,
    actionsble_remark: "",
    yes_no_type: 0,
    set_range_2: 0,
    image_capture: 0,
    action_taken_no: 0,
    action_taken_on_yes: 0,
    count_type: 0,
    remark_yes:'',
    remark_no:''
  });
  const [searchArray, setsearchArray] = useState();
  const [search, setsearch] = useState("");
  const [questionList, setquestionList] = useState();
  const Question = async () => {
    // console.log(localStorage.getItem('AuthToken'))
    try {
      setisLoading(true);
      const userToken = localStorage.getItem("AuthToken");
      setDefaultHeader("token", userToken);
      const params = { question_id: Number(props.match.params.id) };
      console.log("params", params);
      const response = await apiCall("POST", apiEndPoints.QUESTION, params);
      console.log(response, "GETETETE");
      if (response.status === 200) {
        setquestion(response.data.data);
        setsearchArray(response.data.data);
      }
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Question();
  }, []);
  const QuestionList = async () => {
    try {
      setisLoading(true);
      const userToken = localStorage.getItem("AuthToken");
      console.log("userToken", userToken);
      await setDefaultHeader("token", userToken);
      const response = await apiCall("GET", apiEndPoints.QUESTION_LIST);
      console.log("response", response);
      if (response.status === 200) {
        setsearchArray(response.data.data);
        setquestionList(response.data.data);
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    QuestionList();
  }, []);
  const handleQuestionUpdate = async (question_id) => {
    const userToken = localStorage.getItem("AuthToken");
    setDefaultHeader("token", userToken);
    const response = await apiCall("POST", apiEndPoints.Add_QUESTION, question);
    console.log(response, "GETETETE");
    if (response.status === 200) {
      window.location.assign("/dashboard/questionlist");
    }
  };
  return (
    <>
      {isLoading && <h5>Loading...</h5>}
      <QuestionComponent
        question={question}
        setquestion={setquestion}
        handleQuestionUpdate={handleQuestionUpdate}
        search={search}
        setsearch={setsearch}
        searchArray={searchArray}
        setsearchArray={setsearchArray}
        questionList={questionList}
        setquestionList={setquestionList}
        yes_no={yes_no}
        setyes_no={setyes_no}
      />
    </>
  );
}

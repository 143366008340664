import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/common/breadcrumb";
import Search from "../../../components/Search";
import apiEndPoints from "../../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";

export default function BranchList() {
  const [branchList, setbranchList] = useState();
  const [search, setsearch] = useState("");
  const [searchArray, setsearchArray] = useState();

  const BranchList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const response = await apiCall("POST", apiEndPoints.BRANCH_LIST);
    if (response.status === 200) {
      setbranchList(response.data.data);
      setsearchArray(response.data.data);
      console.log('the response branchList =======>', response?.data?.data);
    }
  };
  useEffect(() => {
    BranchList();
  }, []);
  const columns = [
    {
      name: "Manager",
      selector: (row) => row.branch_manager,
      sortable: true,
    },
    {

      name: "Branch",
      selector: (row) => row.branch_name,
      sortable: true,
    },

    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div style={{ alignItems: "center" }}>
          <Link to={`/dashboard/branch/${row.branch_id}`}>
            <i class="fas fa-eye"></i>
          </Link>
          {/* <Link
            to="/dashboard/branchlist"
            style={{ marginLeft: 10 }}
            onClick={() => HandleDelete(row.branch_id)}
          >
            <i
              class="fa fa-trash "
              aria-hidden="true"
              style={{ color: "red" }}
            ></i>
          </Link> */}
        </div>
      ),
    },
  ];



  const HandleDelete = async (id) => {
    if (window.confirm("Are you sure want to delete the Branch!")) {
      const params = { branch_id: id };
      const response = await apiCall(
        "POST",
        apiEndPoints.DELETE_BRANCH,
        params
      );
      if (response.status = 200) {
        window.location.reload()
      }
    }
  };

  // console.log("BEAN;",branchList)
  return (
    <>
      <Breadcrumb title="Branch List" parent="Branch" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header" >
                <div style={{ borderRadius: '20px', outline: '10px', border: '0.2px solid lightgray', width: '750px' }}>
                  <Search
                    search={search}
                    setsearch={setsearch}
                    title={"Search By Branch Name"}
                  /></div>


              </div>
              <div className="card-block row">
                <div className="col-sm-12 col-lg-12 col-xl-12">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={
                        branchList &&
                        branchList.filter((data) => {
                          if (search == "") return searchArray;
                          else if (
                            data.branch_name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                            return data;
                        })
                      }
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import React, { useState, useRef, useEffect } from "react";
export default function ExcelExport(props) {
     const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
     const fileExtension = ".xlsx";


     const SetData = (csvData) => {
          let jsonList = [];

          csvData?.forEach(element => {
               let jsonData = {};
               jsonData['Branch Name'] = element.branch_name;
               jsonData['Branch Code'] = element.branch_code;
               jsonData['City'] = element.city_name;
               jsonData['State'] = element.state;
               jsonData['RPH'] = element.RPH;
               jsonData["SRPM"] = element.SRPM;
               jsonData["RPM"] = element.RPM;
               jsonData['Review Date'] = element.audit_date? element.audit_date:'';
               jsonData['Review Time'] =   element.audit_time?.replace("-", ":");
               jsonData['Review By'] = element.name;
               jsonData['Role'] = element.role_name;
               jsonData['Review Type'] =  element.audit_type == 1 ? 'Online' : "Physical";
            
               jsonList.push(jsonData);
          });
          const ws = XLSX.utils.json_to_sheet(jsonList);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, props.fileName + fileExtension);
        //  console.log("Csv Header", props.csvHeader);

     }
     function GetQuestionData(data) {

          if (data.question_type == "1") {
               return (<div>{data.yes_no} <br></br>Remark:  {data.remark}</div>)
          }
          else if (data.question_type == "2") {
               return (<div>{data.quality} <br></br>Remark: {data.remark}</div>)
          }


     }
     function AddQuestionHeader(jsonData, questionArray) {


          if (questionArray.length > 0) {
               for (let i = 0; i < questionArray.length; i++) {
                    if (questionArray[i].question_type == "1") {
                         jsonData[questionArray[i].audit_question] = questionArray[i].yes_no;

                    }
                    else if (questionArray[i].question_type == "2") {
                         jsonData[questionArray[i].audit_question] = questionArray[i].quality;

                    }
                    jsonData['Remark-' + questionArray[i].audit_question] = questionArray[i].remark;


               }

          }
     }



     const exportToCSV = (csvData, fileName) => {
          SetData(csvData);
         // console.log("Export Data", csvData, fileName);



     }
     return (
          <div>
               <button  className="btn btn-primary" onClick={(e) => exportToCSV(props.csvData, props.fileName)}>Export</button>
          </div>);

}


import React, { useEffect, useLayoutEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import apiEndPoints from "../../../utils/apiEndPoints";
import {
  apiCall,
  setDefaultHeader,
  successToast,
} from "../../../utils/httpClient";
import "./addEmploye.css";

const AddEmploye = () => {
  const [employeData, setEmployeData] = useState({
    emp_code: "",
    phone: "",
    name: "",
    email: "",
    password: "",
    emp_role_id: "",
  });
  const [employe_role_id_list, setEmploye_role_id_list] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [csvScreen, setCsvScreen] = useState(false);
  const [inputData, setInputData] = useState({
    employee_roal_type: "",
    csv_file: "",
  });
  const [formErr, setFormErr] = useState({});
  const [resp, setResp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userToken = localStorage.getItem("AuthToken");
    setDefaultHeader("token", userToken);
    getEmployeeRoleId();
  }, []);

  function emailValidationChk() {
    let formErrors = {};
    let formIsValid = true;
    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  
    if (!employeData.name) {
      formIsValid = false;
      formErrors["name"] = "Enter Employee Name";
    }
    if (!employeData.password) {
      formIsValid = false;
      formErrors["password"] = "Enter Password";
    }
    if (!employeData.emp_role_id) {
      formIsValid = false;
      formErrors["emp_role_id"] = "Select Employee role";
    }
    if (!employeData.email || emailValid.test(employeData.email) === false) {
      formIsValid = false;
      formErrors["email"] = "Enter valid Email!";
    }
    setFormErrors(formErrors);
    return formIsValid;
  }

  const getEmployeeRoleId = async () => {
    try {
      const response = await apiCall("GET", apiEndPoints.GETEMPLOYEEROLEID);
      if (response.status === 200) {
        setEmploye_role_id_list(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emp_r_id = employe_role_id_list.map((x) => {
    return (
      <>
        <option value={x.user_role_id}>{x.role_name}</option>;
      </>
    );
  });

  const employeDataInsert = async () => {
    if (emailValidationChk()) {
      try {
        const userToken = localStorage.getItem("AuthToken");
        setDefaultHeader("token", userToken);
        const response = await apiCall(
          "POST",
          apiEndPoints.EMPLYEEREGISTER,
          employeData
        );
        console.log("EMPLYEEREGISTER", response);
        if (response.status === 200) {
          successToast(response?.data?.message);
          //   window.location.href = "/dashboard/employeList";
          window.location.reload();
        }
        else {
          successToast(response?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Breadcrumb title="Employee" parent="Add Employee" />
      <div className="card">
        <div className="card-header">
          {/* <button className="add-emp-btn" onClick={(e) => setCsvScreen(true)}>
        Upload CSV
      </button> */}
          {/* {inputData.employee_roal_type ? (
        <a className="add-emp-btn" download onClick={handleCsvDownload}>
          <span className="download_csv_txt">
            Download Sample
            {inputData.employee_roal_type === 3
              ? " RMM"
              : inputData.employee_roal_type === 4
                ? " AH"
                : inputData.employee_roal_type === 5
                  ? " RMH"
                  : ""}
            CSV
          </span>
        </a>
      ) : (
        ""
      )} */}
          <div className="container-fluid">
            {csvScreen == false ? (
              <div>
                {/* <button className="add-emp-btn" onClick={(e) => setCsvScreen(true)}>
                  Upload CSV
                </button> */}
                <form className="form theme-form">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Employee Code
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Employee Code"
                              value={employeData.emp_code}
                              onChange={(e) =>
                                setEmployeData({
                                  ...employeData,
                                  emp_code: e.target.value,
                                })
                              }
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                // marginLeft: "10px",
                              }}
                            >
                              {!employeData.emp_code &&
                              formErrors &&
                              formErrors.emp_code
                                ? formErrors.emp_code
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Employee Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Employee Name"
                              value={employeData.name}
                              onChange={(e) =>
                                setEmployeData({
                                  ...employeData,
                                  name: e.target.value,
                                })
                              }
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                // marginLeft: "10px",
                              }}
                            >
                              {!employeData.name &&
                              formErrors &&
                              formErrors.name
                                ? formErrors.name
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Employee Email"
                              value={employeData.email}
                              onChange={(e) =>
                                setEmployeData({
                                  ...employeData,
                                  email: e.target.value,
                                })
                              }
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                // marginLeft: "10px",
                              }}
                            >
                              {!employeData.email ||
                              (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                employeData.email
                              ) === false &&
                                formErrors &&
                                formErrors.email)
                                ? formErrors.email
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="password"
                              placeholder="Password"
                              value={employeData.password}
                              onChange={(e) =>
                                setEmployeData({
                                  ...employeData,
                                  password: e.target.value,
                                })
                              }
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                // marginLeft: "10px",
                              }}
                            >
                              {!employeData.password &&
                              formErrors &&
                              formErrors.password
                                ? formErrors.password
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              maxLength={10}
                              type="text"
                              placeholder="Phone"
                              value={employeData.phone}
                              onChange={(e) =>
                                setEmployeData({
                                  ...employeData,
                                  phone: e.target.value,
                                })
                              }
                              onKeyPress={(event) => {
                                if (/^[a-z]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                // marginLeft: "10px",
                              }}
                            >
                              {!employeData.phone ||
                              (employeData.phone.length != 10 &&
                                formErrors &&
                                formErrors.phone)
                                ? formErrors.phone
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Employee Role
                          </label>
                          <div className="col-sm-9">
                            <select
                              id=""
                              className="form-control"
                              value={employeData.emp_role_id}
                              onChange={(e) =>
                                setEmployeData({
                                  ...employeData,
                                  emp_role_id: Number(e.target.value),
                                })
                              }
                            >
                              <option value="">select Role-</option>
                              {emp_r_id}
                            </select>
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                                // marginLeft: "10px",
                              }}
                            >
                              {!employeData.emp_role_id &&
                              formErrors &&
                              formErrors.emp_role_id
                                ? formErrors.emp_role_id
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-sm-9 offset-sm-3">
                      <button
                        className="btn btn-primary mr-1"
                        type="button"
                        onClick={() => employeDataInsert()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmploye;



export const Pagination = ({ activePage, count, rowsPerPage, totalPages, setActivePage }) => {
     const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
     const end = activePage === totalPages ? count : beginning + rowsPerPage - 1
     return (
          <div className="pagination">
               <button className="btn btn-default" onClick={() => setActivePage(1)}>⏮️ First</button>
               <button className="btn btn-default" onClick={() => activePage > 1 ? setActivePage(activePage - 1) : null}>⬅️ Previous</button>
               <button className="btn btn-default" onClick={() => activePage < totalPages ? setActivePage(activePage + 1) : null}>Next ➡️</button>
               <button className="btn btn-default" onClick={() => setActivePage(totalPages)}>Last ⏭️</button>
               <p>
                    Page {activePage} of {totalPages}
               </p>
               <p>
                    Rows: {beginning === end ? end : `${beginning} - ${end}`} of {count}
               </p>
          </div>

     )
}

export function toLower(value) {
     if (isString(value)) {
          return value.toLowerCase()
     }
     return value
}
export function isEmpty(obj = {}) {
     return Object.keys(obj).length === 0
}
export function isNil(value) {
     return typeof value === 'undefined' || value === null
}
export function convertType(value) {
     if (isNumber(value)) {
          return value.toString()
     }

     if (isDateString(value)) {
          return convertDateString(value)
     }

     if (isBoolean(value)) {
          return value ? '1' : '-1'
     }

     return value
}
export function isNumber(value) {
     return typeof value == 'number' && !isNaN(value)
}
export function convertDateString(value) {
     return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2)
}

export function isBoolean(value) {
     return value === true || value === false
}
export function isDateString(value) {
     if (!isString(value)) return false

     return value.match(/^\d{2}-\d{2}-\d{4}$/)
}
export function isString(value) {
     return typeof value === 'string' || value instanceof String
}
export function sortRows(rows, sort) {

     return rows?.sort((a, b) => {
          const { order, orderBy } = sort

          if (isNil(a[orderBy])) return 1
          if (isNil(b[orderBy])) return -1

          const aLocale = convertType(a[orderBy])
          const bLocale = convertType(b[orderBy])

          if (order === 'asc') {
               return aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(b[orderBy]) })
          } else {
               return bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(a[orderBy]) })
          }
     })
}
export function filterRows(rows, filters) {
     if (isEmpty(filters)) return rows
     console.log("fitler", filters);
     return rows?.filter((row) => {
          return Object.keys(filters).every((accessor) => {
               const value = row[accessor]
               const searchValue = filters[accessor]
               
               if (isString(value)) {
                    return toLower(value).includes(toLower(searchValue))
               }

               if (isBoolean(value)) {
                    return (searchValue === 'true' && value) || (searchValue === 'false' && !value)
               }

               if (isNumber(value)) {
                    return value == searchValue
               }

               return false
          })
     })
}
import React, { useState } from 'react'
import apiEndPoints from '../../utils/apiEndPoints'
import { apiCall } from '../../utils/httpClient'
import ProfileComponent from './component/ProfileComponent'

export default function Profile() {
    const [userData,setuserData]=useState(JSON.parse(localStorage.getItem('userData')))
    const [message, setmessage] = useState()
    const UpdateProfile=async()=>{
        const params={
            name:userData.name,
            email:userData.email,
            phone:userData.phone
        }
        const response=await apiCall('POST',apiEndPoints.PROFILE_UPDATE,params)
        // console.log(response)
        if(response.status===200)
        {
            localStorage.setItem('userData',JSON.stringify(response.data.data))
            setmessage(response.data.message)
            
        }else{
            console.log(response,"ERROR")
        }
    }
    console.log(message)
    return (
     <ProfileComponent userData={userData} setuserData={setuserData} UpdateProfile={UpdateProfile} message={message}/>   
    )
}

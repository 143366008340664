import React, { useState } from "react";
import "./style.css";
import moment from "moment";
import { Table } from "react-bootstrap";

import { BASE_URL, S3_URL } from '../../../utils/constants.js';
export default function ReportDataPdf(props) {

     const { list } = props;
     const [toggleFrom, settoggleFrom] = useState(false);
     const [toggleto, settoggleto] = useState(false);


     let auditRecord = {};
     // if (list) {
     //      auditRecord = list;
     // }
     // else {

     // }
     const gridCols = [[], []];
     let imageCount = 0;
     function getImageTimeStamp(imageName) {
          let imageNameArray = imageName.split("_");
          let timeStamp = imageNameArray[imageNameArray.length - 1];
          timeStamp = timeStamp.substring(0, timeStamp.length - 4);
          return timeStamp;
     }
     var loadingCount = 0;
     const LoadImage = (index, imageName) => {
          loadingCount++;
          index = index.substring(4, index.length);
          console.log(loadingCount, imageCount);
          if (loadingCount === imageCount) {
               console.log(loadingCount, imageCount);
               props.setPrintFlag(true);

          }



     };

     const ErrorLoadImage = (index, imageName, auditId) => {


     };



     function GetImageTag(imageName, auditId, auditType, lat, long, imageDate) {
        //  console.log(lat, long);
          let imageCountLable = "";
          if (imageName != "" && imageName != undefined) {
               if (auditType == "Online") {
                    if (imageName.indexOf("VirtualReviewImage") >= 0) {
                         imageCount++;
                         imageCountLable = 'img_' + imageCount;

                         return <figure id={'imageTag_' + imageCount} key={'imageTag_' + imageCount}><img src={S3_URL + imageName} className="onlineImage" onLoad={() => LoadImage(imageCountLable, imageName)} onError={ErrorLoadImage(imageCountLable, imageName, auditId)} />
                              <figcaption>{lat},{long}<br></br>{getImageTimeStamp(imageName)}</figcaption>
                         </figure>

                    }
                    else {
                         imageCount++;
                         imageCountLable = 'img_' + imageCount;

                         return <figure id={'imageTag_' + imageCount} key={'imageTag_' + imageCount}><img src={BASE_URL + 'upload/question/' + imageName} onLoad={() => LoadImage(imageCountLable, imageName)} onError={ErrorLoadImage(imageCountLable, imageName, auditId)} /><figcaption>{lat},{long}<br></br>{imageDate}</figcaption></figure>

                    }

               }
               else {
                    imageCount++;
                    imageCountLable = 'img_' + imageCount;

                    return <figure id={'imageTag_' + imageCount} key={'imageTag_' + imageCount}><img src={BASE_URL + 'upload/question/' + imageName} onLoad={() => LoadImage(imageCountLable, imageName)} onError={ErrorLoadImage(imageCountLable, imageName, auditId)} /></figure>
               }
          }

     }
     function GetImageData(auditId, auditType, image, lat, long, imageArray) {

          const imageArrayHtmlData = [];
          if (auditType == "Physical") {
               if (image.indexOf(",") > 0) {
                    imageArray = image.split(",");
                    imageArrayHtmlData.push(GetImageTag(imageArray[0], auditId, auditType, lat, long, ''))
               }
               else {
                    imageArrayHtmlData.push(GetImageTag(image, auditId, auditType, lat, long, ''));
               }
          }
          else {
               imageArrayHtmlData.push(GetImageTag(imageArray[0]?.image_data, auditId, auditType, lat, long, imageArray[0]?.imageDateTime))

          }
          return imageArrayHtmlData;
     }

     function GetDisplayPosterTd(auditRecord, questionIndex, imageFilter, header) {


          return (<div className='col-4 tdBorder'>{header}
               {
                    auditRecord.questionArray[questionIndex]?.question_type == 1 ?
                         auditRecord.questionArray[questionIndex]?.yes_no :
                         auditRecord.questionArray[questionIndex]?.quality
               }

          </div>);

     }
     function GetDisplayPosterImage(auditRecord, questionIndex, imageFilter, header) {
          return (<div className='col-4'>
               <div className="row">
                    <div className="col tdBorder">
                         <strong>{header}</strong>
                         {
                              auditRecord.questionArray[questionIndex]?.question_type == 1 ?
                                   auditRecord.questionArray[questionIndex]?.yes_no :
                                   auditRecord.questionArray[questionIndex]?.quality
                         }
                    </div>
               </div>

               <div className="row">
                    <div className="col tdBorder">
                         {
                              auditRecord.questionArray[questionIndex]?.question_type == 1 ?
                                   auditRecord.questionArray[questionIndex]?.yes_no == "YES" ?
                                        GetImageTag(imageFilter.image_data, auditRecord.audit_id, auditRecord.audit_type, auditRecord.audit_latitude, auditRecord.audit_longitude, '')
                                        : null
                                   : null
                         }
                    </div>
               </div>
          </div>);
     }
     function GetDisplayPosterImageOffline(auditRecord, questionIndex, imageFilter, header) {
          return (<div className='col-4 tdBorder' >
               <div className="row  tdBorder">
                    <div className="col">
                         <strong>{header}</strong>
                         {
                              auditRecord.questionArray[questionIndex]?.question_type == 1 ?
                                   auditRecord.questionArray[questionIndex]?.yes_no :
                                   auditRecord.questionArray[questionIndex]?.quality
                         }
                    </div>
               </div>

               <div className="row  tdBorder">
                    <div className="col">
                         {
                              auditRecord.questionArray[questionIndex]?.question_type == 1 ?
                                   auditRecord.questionArray[questionIndex]?.yes_no == "YES" ?
                                        GetImageTag(imageFilter, auditRecord.audit_id, auditRecord.audit_type, auditRecord.audit_latitude, auditRecord.audit_longitude, '')
                                        : null
                                   : null
                         }
                    </div>
               </div>
          </div>);
     }

     function DisplayPosterQuestion(trArray, auditRecord, questionIndex, questionId, questionTitle) {

          let imageFilter = auditRecord.images.filter((r) => r.question_id == questionId);

          if (imageFilter.length > 0) {
               //rowCount += imageFilter.length;
               imageFilter.forEach((imageData) => {
                    trArray.push(GetDisplayPosterImage(auditRecord, questionIndex, imageData, questionTitle));
               });
          }
          else {
               //rowCount++;
               trArray.push(GetDisplayPosterImage(auditRecord, questionIndex, null, questionTitle));
          }

     }

     function DisplayPosterQuestionOffline(trArray, auditRecord, questionIndex, questionId, questionTitle) {

          const image = auditRecord.questionArray[questionIndex].image_capture;

          if (image.indexOf(",") > 0) {
               const imageArray = image.split(",");
               //rowCount += imageArray.length;
               imageArray.forEach((imageData) => {
                    trArray.push(GetDisplayPosterImageOffline(auditRecord, questionIndex, imageData, questionTitle));
               });
          }
          else {
               // rowCount++;
               trArray.push(GetDisplayPosterImageOffline(auditRecord, questionIndex, image, questionTitle));
          }
     }


     function DisplayPosterHeader(auditRecord) {
          var divArray = [];
          if (auditRecord.audit_type == "Online") {
               DisplayPosterQuestion(divArray, auditRecord, 0, 567, "2) Poster displayed?")
               DisplayPosterQuestion(divArray, auditRecord, 1, 568, "3) Standee displayed?")
               DisplayPosterQuestion(divArray, auditRecord, 3, 571, "4) Content non co-branded?")
          }
          else {
               DisplayPosterQuestionOffline(divArray, auditRecord, 0, 567, "2) Poster displayed?")
               DisplayPosterQuestionOffline(divArray, auditRecord, 1, 568, "3) Standee displayed?")
               DisplayPosterQuestionOffline(divArray, auditRecord, 3, 571, "4) Content non co-branded?")
          }
          return divArray;
     }

     function SetContentRow(auditRecord) {
          const divArray = DisplayPosterHeader(auditRecord);
          return divArray;
     }

     function GenerateInnerRow(auditRecord) {
          var trArray = [];
          trArray.push(<div key={'questionTd_' + auditRecord.audit_id + "_audit_details"} className="row tdBorder">
               <div className="col">
                    <div className="row">
                         <div className="col-10 tdBorder">
                              <strong>Review Id:</strong>{auditRecord?.audit_id}
                              <strong> Branch Name:</strong>{auditRecord?.branch_name}
                              <strong>  Insurar:</strong>{auditRecord?.insurance_com}
                              <strong>  Audit Type:</strong> {auditRecord?.audit_type}

                              <strong>  Review date:</strong> {auditRecord?.audit_date}  Review By {auditRecord?.audit_type == "Physical" ? auditRecord?.auditorName + ' (' + auditRecord?.role_name + ')' : auditRecord?.branch_manager + ' (BM)'}
                         </div>
                         <div className="col-2 tdBorder"><strong>1) Quantity?</strong> {auditRecord.questionArray[2]?.question_type == 1 ? auditRecord.questionArray[2]?.yes_no : auditRecord.questionArray[2]?.quality}</div>
                    </div>
                    <div className="row tdBorder">
                         {SetContentRow(auditRecord)}
                    </div>
               </div>
          </div>);
          // trArray.push(<div className="row tdBorder">
          //      {SetContentRow(auditRecord)}
          // </div>)
          // trArray.push(<div className="row tdBorder">

          // </div>)


          // trArray.push(<tr key={'questionTd_' + auditRecord.audit_id + "_audit_details"}>
          //      <td style={{ width: '300px', border: '1px solid #cccccc' }} colSpan={4}>
          //           <strong>Review Id:</strong>{auditRecord?.audit_id}
          //           <strong> Branch Name:</strong>{auditRecord?.branch_name}
          //           <strong>  Insurar:</strong>{auditRecord?.insurance_com}
          //           <strong>  Audit Type:</strong> {auditRecord?.audit_type}

          //           <strong>  Review date:</strong> {auditRecord?.audit_date}  Review By {auditRecord?.audit_type == "Physical" ? auditRecord?.auditorName + ' (' + auditRecord?.role_name + ')' : auditRecord?.branch_manager + ' (BM)'}
          //      </td>
          //      <td>1) Quantity? {rowData?.question_type == 1 ? rowData?.yes_no : rowData?.quality}</td>
          // </tr>);
          //SetContentRow(trArray, auditRecord);
          // trArray.push(<tr key={'questionTd_' + auditRecord.audit_id + "_audit_header"}>

          //      {DisplayPosterHeader(auditRecord, 0)}
          //      {/* {DisplayPoster(auditRecord,1)}

          //      {DisplayPoster(auditRecord,3)} */}


          //      {/* <th style={{ border: '1px solid #cccccc' }}>1) Poster displayed? {auditRecord.questionArray[0]?.question_type == 1 ? auditRecord.questionArray[0]?.yes_no : auditRecord.questionArray[0]?.quality}</th> */}
          //      {/* <th style={{ border: '1px solid #cccccc' }}>2) Standee displayed? {auditRecord.questionArray[1]?.question_type == 1 ? auditRecord.questionArray[1]?.yes_no : auditRecord.questionArray[1]?.quality}</th>
          //      <th style={{ border: '1px solid #cccccc' }}>3) Quantity?</th>
          //      <th style={{ border: '1px solid #cccccc' }}>4) Content non co-branded? {auditRecord.questionArray[3]?.question_type == 1 ? auditRecord.questionArray[3]?.yes_no : auditRecord.questionArray[3]?.quality}</th> */}
          // </tr>);

          // trArray.push(<tr key={'questionTd_' + auditRecord.audit_id + "_audit_row"} >
          //      {
          //           props.list
          //                ? auditRecord.questionArray.map((rowData, index) => (
          //                     rowData?.question_type == 1 ?
          //                          <td key={'questionTd_' + auditRecord.audit_id + "_" + index} className="tdBorder" style={{ verticalAlign: 'top' }}>
          //                               {/* {rowData?.question_type == 1 ? rowData?.yes_no : rowData?.quality}<br></br> */}
          //                               {rowData?.question_type == 1 ? rowData?.yes_no == 'YES' ? GetImageData(auditRecord?.audit_id, auditRecord?.audit_type, rowData.image_capture, auditRecord.audit_latitude, auditRecord.audit_longitude, auditRecord.images.filter(r => r.question_id == rowData.question_id)) : null : null}
          //                               {/* <br></br> */}
          //                               {/* <label style={{ verticalAlign: 'bottom' }}>
          //                               <strong>Remark</strong>:<br></br> {rowData?.remark}
          //                          </label> */}
          //                          </td> :
          //                          <td key={'questionTd_' + auditRecord.audit_id + "_" + index} className="tdBorder" style={{ verticalAlign: 'top' }}>
          //                               {rowData?.question_type == 1 ? rowData?.yes_no : rowData?.quality}
          //                               {/* <br></br> */}
          //                               {/* <label style={{ verticalAlign: 'bottom' }}>
          //                               <strong>Remark</strong>:<br></br> {rowData?.remark}
          //                          </label> */}
          //                          </td>

          //                )) : null

          //      }

          // </tr>)
          return trArray;

     }
     function GetNumberOfRow(auditRecord) {
          var rowCount = 0;
          auditRecord.questionArray.forEach((element) => {
               if (element.yes_no == "YES") {
                    const reviewImages = element.image_capture.split(",");
                    rowCount += reviewImages.length;
               }
               else if (element.yes_no == "NO") {
                    rowCount++;
               }

          });
          rowCount = rowCount % 3;
          if (rowCount == 0) {
               rowCount = 1;
          }

          return rowCount;

     }
     function NoFlagRecord(auditRecord) {
          var noFlag = true;
          auditRecord.questionArray.forEach((questionRow) => {
               if (questionRow.yes_no == "YES") {
                    noFlag = false;
               }
          });
          return noFlag;
     }
     function GenerateRowArray(noFlag) {
          var contentRows = [];
          props.list?.forEach((auditRecord, index) => {
               var contentRowObject = {};
               if (NoFlagRecord(auditRecord) == noFlag) {
                    contentRowObject.reviewId = auditRecord.audit_id;
                    contentRowObject.branchName = auditRecord.branch_name;
                    contentRowObject.insurar = auditRecord.insurance_com;
                    contentRowObject.auditType = auditRecord.audit_type;
                    contentRowObject.reviewBy = auditRecord?.audit_date + '  Review By  ';
                    auditRecord?.audit_type == "Physical" ? contentRowObject.reviewBy += auditRecord?.auditorName + ' (' + auditRecord?.role_name + ')'
                         : contentRowObject.reviewBy += auditRecord?.branch_manager + ' (BM)';
                    contentRowObject.quality = auditRecord.questionArray[2]?.question_type == 1 ? auditRecord.questionArray[2]?.yes_no : auditRecord.questionArray[2]?.quality;
                    contentRowObject.rowType = 'HEADER';
                    contentRowObject.images = [];

                    contentRows.push(contentRowObject);
                    var contentRowQuestions = [];
                    auditRecord.questionArray.forEach((questionRow) => {

                         if (questionRow.yes_no == "YES") {

                              const reviewImages = questionRow.image_capture.split(",");

                              reviewImages.forEach(imageObj => {
                                   contentRowObject = {};
                                   contentRowObject.answer = questionRow.yes_no;
                                   contentRowObject.auditType = auditRecord.audit_type;
                                   contentRowObject.reviewId = auditRecord.audit_id;
                                   contentRowObject.lat = auditRecord.audit_latitude;
                                   contentRowObject.long = auditRecord.audit_longitude;

                                   if (auditRecord.audit_type == "Online") {
                                        contentRowObject.imageName = auditRecord.images.filter(r => r.image_data == imageObj);
                                   }
                                   else {
                                        contentRowObject.imageName = imageObj;
                                   }


                                   if (questionRow.question_id == 567) {
                                        contentRowObject.imageTitle = "1) Poster displayed?";
                                   }
                                   else if (questionRow.question_id == 568) {
                                        contentRowObject.imageTitle = "2) Standee displayed?";
                                   }
                                   else if (questionRow.question_id == 571) {
                                        contentRowObject.imageTitle = "3) Content non co-branded?";
                                   }
                                   contentRowQuestions.push(contentRowObject);

                              });

                         }
                         else if (questionRow.yes_no == "NO") {
                              contentRowObject = {};
                              contentRowObject.answer = questionRow.yes_no;
                              contentRowObject.imageName = "";
                              if (questionRow.question_id == 567) {
                                   contentRowObject.imageTitle = "1) Poster displayed?";
                              }
                              else if (questionRow.question_id == 568) {
                                   contentRowObject.imageTitle = "2) Standee displayed?";
                              }
                              else if (questionRow.question_id == 571) {
                                   contentRowObject.imageTitle = "3) Content non co-branded?";
                              }
                              contentRowQuestions.push(contentRowObject);
                         }



                    });
                    GenerateRowArraySetImages(contentRowQuestions, contentRows, 3, auditRecord.audit_id);

               }
          });

         // console.log(contentRows);
          return contentRows;
     }

     function GenerateRowArraySetImages(contentRowQuestions, contentRows, chunkSize, audit_id) {

          for (let i = 0; i < contentRowQuestions.length; i += chunkSize) {
               const chunk = contentRowQuestions.slice(i, i + chunkSize);
               var contentRowObject = {};
               contentRowObject.rowType = 'CONTENT';
               contentRowObject.reviewId = audit_id;
               contentRowObject.images = chunk;
               contentRows.push(contentRowObject);
          }
     }


     function GenerateRowArrayImageColHeading(images, divId) {

          var divArray = [];
          images.forEach((imageObj, index) => {

               divArray.push(<div className="col-4" key={divId + "_" + index}>
                    <div className="row ">
                         <div className="col tdBorder">
                              <strong>{imageObj.imageTitle}</strong> {imageObj.answer}
                         </div>
                    </div>
                    <div className="row">
                         <div className="col tdBorder">
                              {
                                   imageObj.auditType == "Online" ?
                                        GetImageTag(imageObj?.imageName[0]?.image_data, imageObj.reviewId, imageObj.auditType, imageObj.lat, imageObj.long, imageObj?.imageName[0]?.imageDateTime)
                                        :
                                        GetImageTag(imageObj.imageName, imageObj.reviewId, imageObj.auditType, "", "", '')

                              }

                         </div>
                    </div>
               </div>);
          })
          return divArray;
     }

     function GenerateRow() {
          var contentRow = GenerateRowArray(false);

          var trArray = [];
          var pageBrackFlag = false;
          var contentCount = 0;
          contentRow.forEach((auditRecord, index) => {
               var style = "";

               style = { pageBreakAfter: "avoid" }

               if (auditRecord.rowType == "HEADER") {
                    pageBrackFlag = false;

                    trArray.push(<div className="row" style={style} key={'questionTd_' + auditRecord.reviewId + "_audit_details_table"}>
                         <div className="col-10 tdBorder">
                              <strong>Review Id:</strong>{auditRecord.reviewId}
                              <strong> Branch Name:</strong>{auditRecord?.branchName}
                              <strong>  Insurer:</strong>{auditRecord?.insurar}
                              <strong>  Review Type:</strong> {auditRecord?.auditType}

                              <strong>  Review date:</strong> {auditRecord?.reviewBy}
                         </div>
                         <div className="col-2 tdBorder" ><strong>1) Quantity?</strong> {auditRecord.quality}</div>
                    </div>)
               }
               else {
                    contentCount++;
                    if (contentCount == 3) {
                         contentCount = 0;
                         style = { pageBreakAfter: "always" }
                    }
                    trArray.push(<div className="row  tdBorder" style={style} key={'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content"}>

                         {GenerateRowArrayImageColHeading(auditRecord.images, 'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content")}

                    </div>
                    )
               }

          })

          contentRow = GenerateRowArray(true);

          contentRow.forEach((auditRecord, index) => {
               if (auditRecord.rowType == "HEADER") {
                    pageBrackFlag = false;

                    trArray.push(<div className="row" key={'questionTd_' + auditRecord.reviewId + "_audit_details_table"}>
                         <div className="col-10 tdBorder">
                              <strong>Review Id:</strong>{auditRecord.reviewId}
                              <strong> Branch Name:</strong>{auditRecord?.branchName}
                              <strong>  Insurar:</strong>{auditRecord?.insurar}
                              <strong>  Review Type:</strong> {auditRecord?.auditType}

                              <strong>  Review date:</strong> {auditRecord?.reviewBy}
                         </div>
                         <div className="col-2 tdBorder" ><strong>1) Quantity?</strong> {auditRecord.quality}</div>
                    </div>)
               }
               else {

                    trArray.push(<div className="row  tdBorder" key={'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content"}>

                         {GenerateRowArrayImageColHeading(auditRecord.images, 'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content")}

                    </div>
                    )
               }

          })

          {
               // props.list?.forEach((auditRecord, index) => {

               //      const rowCount = GetNumberOfRow(auditRecord);

               //      var pageBreakAfter = index + rowCount;
               //      //console.log("rows",rowCount,pageBreakAfter,index,(pageBreakAfter % 3));
               //      //pageBreakAfter % 3 == 0 ?

               //      // trArray.push(<div className="row" style={{ pageBreakAfter: "always" }} key={'questionTd_' + auditRecord.audit_id + "_audit_details_table"}>
               //      //      <div className="col">
               //      //           {GenerateInnerRow(auditRecord)}
               //      //      </div>
               //      // </div>)
               //      // :
               //      trArray.push(<div className="row" key={'questionTd_' + auditRecord.audit_id + "_audit_details_table"}>
               //           <div className="col">
               //                {GenerateInnerRow(auditRecord)}
               //           </div>
               //      </div>)

               //      // console.log("rowCount", rowCount);

               // });

          }
          return trArray;

     }

     return (

          <div style={{ margin: '10px', display: "none" }} id='reportDataInPdf'>
               {props.printFlagStart == true ?

                    <div>
                         <h4>Branch Review Report</h4>
                         {GenerateRow()}
                    </div>
                    :
                    null
               }



          </div>
     );
}

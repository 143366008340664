import axios from 'axios'
import { GLOBAL_URL } from './constants'
import "react-toastify/dist/ReactToastify.css";
import { Flip, toast } from 'react-toastify';
const httpClient = axios.create({
    baseURL: `${GLOBAL_URL}/api/v1/`,
});

export function setDefaultHeader(header, value) {
    httpClient.defaults.headers.common[header] = value
}

export async function apiCall(method, url, data, header = {
    'Content-Type': 'application/json'
}) {
    try {
        const res = await httpClient({
            method,
            url,
            data,
            headers: header,
            withCredentials: false
        })
        return res
    } catch (error) {
        if (error.response) {
            if (error.response.status === 403) {
                localStorage.removeItem("AuthToken")
            }
            console.log('Error data : ', error.response.data);
            console.log('Error status : ', error.response.status);
            console.log('Error headers : ', error.response.headers);
        } else if (error.request) {
            console.log('Error request : ', error.request);
        } else {
            console.log('Error message : ', error.message);
        }
        console.log("Error config", error.config);
        // console.log("errorresponse", error.response);
        console.log("Error", error);
        return false
    }
}

export const successToast = (msg) => {
    toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
    });
    // window.document.getElementById("loaderoverlay").style.display = "none";
};
export const errorToast = (msg) => {
    toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
    });
    // window.document.getElementById("loaderoverlay").style.display = "none";
};
export const unAuthorizedError = (data) => {
    toast.error(data.message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
    });
    // window.document.getElementById("loaderoverlay").style.display = "none";
    if (data.status === 401 || data.status === 400 || data.status === 403) {
        // store.dispatch(userLogout());
        window.localStorage.clear();
        window.location.reload();
    }
};
export const warnToast = (msg) => {
    toast.warn(msg, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    // window.document.getElementById("loaderoverlay").style.display = "none";
};
export const infoToast = (msg) => {
    toast.info(msg, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    // window.document.getElementById("loaderoverlay").style.display = "none";
};


import React from 'react'

export default function Search(props) {
    const {search,setsearch,title}=props
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <i class="fa fa-search" aria-hidden="true" style={{ position: "absolute", marginLeft: 10, color: "gray" }}></i>
            <input className="form-control-plaintext searchIcon"
                value={search} onChange={(e)=>setsearch(e.target.value)}
                style={{ paddingLeft: 30 ,borderRadius:20,outline:"none"}}
                placeholder={title} type="text" />
        </div>
    )
}

import exportFromJSON from "export-from-json";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import apiEndPoints from "../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../utils/httpClient";
import ReportComponent from "./component/ReportComponent";
export default function Report() {
  const [selected, setselected] = useState(8);
  const [list, setlist] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [header, setheader] = useState();
  var [offset, setoffset] = useState(0);
  const [from, setfrom] = useState(new Date());
  const [to, setto] = useState(new Date());
  const typeRef = useRef(8);
  const [baseURL, setbaseURL] = useState();
  const [search, setsearch] = useState("");
  const [searchArray, setsearchArray] = useState();
  const handleSelect = (id) => {
    setselected(id);
    typeRef.current = id;
    handleAuditInfo("", 0);
  };
  useEffect(() => {
    handleAuditInfo("", 0);
  }, []);

  const handleAuditInfo = async (state, set) => {
    try {
      const userLoginData =  JSON.parse(localStorage.getItem('userData'));
      
      setisLoading(true);
      const params = {
        type: typeRef.current,
        offset: set,
        from_date: moment(from).format("YYYY-MM-DD"),
        to_date: moment(to).format("YYYY-MM-DD"),
      };
      
      const userToken = localStorage.getItem("AuthToken");
      await setDefaultHeader("token", userToken);
     
      var response = []
      if(userLoginData.emp_role_id == 5){
        var response = await apiCall(
          "POST",
          apiEndPoints.GETRMHAUDITINFO,
          params
        );
      }else if(userLoginData.emp_role_id == 4){
        var response = await apiCall(
          "POST",
          apiEndPoints.GETAHAUDITINFO,
          params
        );
      }else{
        var response = await apiCall(
          "POST",
          apiEndPoints.GET_AUDIT_INFO,
          params
        );
      }
      if (response.data.status === 200) {
        setbaseURL(response.data.base_url);
        setlist(response.data.data);
        console.log('===> ', Object.keys(response.data.data[0]));
        setsearchArray(response.data.data);
        if(response.data.header.length==0)
        {
          setheader(Object.keys(response.data.data[0]))
        }else{
          setheader(response.data.header);
        }
        console.log('response.data.header: ', response.data.header);
      }
      if (response.data.status === 201) {
        setlist();
        setheader();
      }
      if (response.data.status !== 201) {
        if (state === "next") {
          setoffset(set);
        }
        if (state === "prev") {
          if (offset > 0) setoffset(set);
        }
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <ReportComponent
        selected={selected}
        handleSelect={handleSelect}
        isLoading={isLoading}
        list={list}
        header={header}
        handleAuditInfo={handleAuditInfo}
        offset={offset}
        from={from}
        setfrom={setfrom}
        to={to}
        setto={setto}
        baseURL={baseURL}
        search={search}
        setsearch={setsearch}
        searchArray={searchArray}
        setsearchArray={setsearchArray}
      />
    </>
  );
}

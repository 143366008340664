import React, { useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import "./style.css";
import exportFromJSON from "export-from-json";
import Calendar from "react-calendar";
import moment from "moment";
import { Table } from "react-bootstrap";
import Search from "../../../components/Search";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export default function ReportComponent(props) {
  const { to, from, setfrom, setto, search } = props;
  const [toggleFrom, settoggleFrom] = useState(false);
  const [toggleto, settoggleto] = useState(false);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const handleDownload = () => {
    const reportData = [];
    const newItem = {};
    props.list.map((item, index) => {
      Object.keys(item).map((key) => {
        const newKey = key.replace(/_/g, " ");
        const caps = newKey.split(" ").map(capitalize).join(" ");
        newItem[caps] = item[key];
      });
      reportData.push(newItem);
    });
    const params = {
      data: reportData,
      fileName: "Report",
      exportType: "xls",
    };
    exportFromJSON(params);
  };
  return (
    <div>
      <Breadcrumb title="Report" parent="Report" />
      <div className="container-fluid">
        <div className="card">
          {props.selected < 4 ? (
            <div className="card-header">
              <Search
                search={props.search}
                setsearch={props.setsearch}
                title={"Search By Name"}
              />
            </div>
          ) : null}
          {/* <div className="main" style={{display:'none'}}>
          {props.selected === 1 ? (
            <button className="btn btn-info" type="submit">RMM</button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(1)}
            >
              RMM
            </button>
          )}
          {props.selected === 2 ? (
            <button className="btn btn-info" type="submit">
              AH
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(2)}
            >
              AH
            </button>
          )}
          { JSON.parse(localStorage.getItem('userData')).emp_role_id != 4 ?
          props.selected === 3 ? (
            <button className="btn btn-info" type="submit">
              RMH
            </button> 
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(3)}
            >
              RMH
            </button>
          ):''}
          {props.selected === 4 ? (
            <button className="btn btn-info" type="submit">
              Inventory
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(4)}
            >
              Inventory
            </button>
          )}
          {props.selected === 5 ? (
            <button className="btn btn-info" type="submit">
              Branch Wise Old
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(5)}
            >
              Branch Wise Old
            </button>
          )}
          {props.selected === 6 ? (
            <button className="btn btn-info" type="submit">
              Branch Wise New
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(6)}
            >
              Branch Wise New
            </button>
          )}
          {props.selected === 7 ? (
            <button className="btn btn-info" type="submit">
              All Branch Audits
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(7)}
            >
              All Branch Audits
            </button>
          )}

        </div>

       
        <br />
        <div className="main main__btm" style={{display:'none'}} >
          {props.selected === 8 ? (
            <button className="btn btn-info" type="submit">
              Detailed Report
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(8)}
            >
              Detailed Report
            </button>
          )}
          {props.selected === 9 ? (
            <button className="btn btn-info" type="submit">
              Branch Wise Report
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(9)}
            >
              Branch Wise Report
            </button>
          )}

          {props.selected === 10 ? (
            <button className="btn btn-info" type="submit">
              Branch Ageing Master
            </button>
          ) : (
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleSelect(10)}
            >
              Branch Ageing Master
            </button>
          )}
        </div> */}

          <div className="main" style={{ marginTop: "10px" }}>
            {props.selected === 8 ? (
              <button className="btn btn-info" type="submit">
                Detailed Report
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => props.handleSelect(8)}
              >
                Detailed Report
              </button>
            )}
            {JSON.parse(localStorage.getItem("userData")).emp_role_id != 4 ? (
              props.selected === 3 ? (
                <button className="btn btn-info" type="submit">
                  RMH Summary
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => props.handleSelect(3)}
                >
                  RMH Summary
                </button>
              )
            ) : (
              ""
            )}
            {props.selected === 4 ? (
              <button className="btn btn-info" type="submit">
                Inventory
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => props.handleSelect(4)}
              >
                Inventory
              </button>
            )}
            {props.selected === 9 ? (
              <button className="btn btn-info" type="submit">
                Branch Wise Report
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => props.handleSelect(9)}
              >
                Branch Wise Report
              </button>
            )}

            {props.selected === 10 ? (
              <button className="btn btn-info" type="submit">
                Branch Ageing Master
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => props.handleSelect(10)}
              >
                Branch Ageing Master
              </button>
            )}
          </div>

          <center style={{ marginBottom: "2.5rem", padding: "1rem" }}>
            <h5>DATE</h5>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                position: "absolute",
                zIndex: 1,
              }}
            >
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => {
                    settoggleFrom(!toggleFrom);
                  }}
                >
                  {from ? `from: ${moment(from).format("DD-MM-YYYY")}` : "From"}
                </button>
                {toggleFrom ? (
                  <Calendar value={from} onChange={setfrom} />
                ) : null}
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => {
                    settoggleto(!toggleto);
                  }}
                >
                  {to ? `to: ${moment(to).format("DD-MM-YYYY")}` : "to"}
                </button>
                {toggleto ? <Calendar value={to} onChange={setto} /> : null}
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => props.handleAuditInfo("", props.offset)}
                >
                  Filter
                </button>
              </div>
            </div>
          </center>

          <div
            className={`table-responsive ${
              props.selected >= 4 ? "tableHeightLen" : "tableHeightSho"
            }`}
          >
            {props.isLoading ? (
              <center>
                <h4>Loading...</h4>
              </center>
            ) : (
              <Table
                className="table_show"
                striped
                bordered
                hover
                id="table-to-xls"
              >
                <thead>
                  <tr className="table_head">
                    {props.header && (
                      <th
                        className={`${
                          props.selected == 10
                            ? "color_purpl"
                            : props.selected == 4
                            ? "yellow_clr"
                            : props.selected == 3 || props.selected == 8
                            ? "inv_clr"
                            : ""
                        }`}
                        style={{ minWidth: "6rem" }}
                      >
                        {"Sr No."}
                      </th>
                    )}

                    {props.header
                      ? props.header.map((head, index) => (
                          <th
                            key={index}
                            style={{ minWidth: "15rem" }}
                            className={`${
                              props.selected == 10
                                ? head == "branch_not_visited_since_days"
                                  ? "yellow_drk"
                                  : head == "last_visit_date"
                                  ? "gray_drk"
                                  : "color_purpl"
                                : props.selected == 4
                                ? head == "branch_code"
                                  ? "yellow_clr"
                                  : head == "name_of_branch"
                                  ? "yellow_clr"
                                  : head == "city"
                                  ? "yellow_clr"
                                  : head == "state"
                                  ? "yellow_clr"
                                  : head == "branch_format"
                                  ? "yellow_clr"
                                  : head == "branch_merchandising_check_date"
                                  ? "yellow_clr"
                                  : head
                                      .replace(/_/g, " ")
                                      .split(" ")
                                      .map(capitalize)
                                      .join(" ") ===
                                    "2(a) Number Of Large Poster Frames In The Branch Other Than The Mandatory Poster Frames As Covered In Point No. 1."
                                  ? "grn_lyt"
                                  : head
                                      .replace(/_/g, " ")
                                      .split(" ")
                                      .map(capitalize)
                                      .join(" ") ===
                                    "2(a) Does The Branch Have Digital Poster Frame Are Available In The Branch (mention Count)"
                                  ? "grn_lyt"
                                  : "inv_clr"
                                : props.selected == 3
                                ? head ==
                                  "no_of_branches_merchandising_check_done"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_unique_branches_merchandising_check_done"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_branches_merchandising_check_done_physically"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_branches_merchandising_check_done_online"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_open_tickets_marketing_actionables"
                                  ? "yellow_ligt"
                                  : head == "no_of_open_tickets_BM_actionables"
                                  ? "yellow_ligt"
                                  : "inv_clr"
                                : props.selected == 8
                                ? head ==
                                  "no_of_branches_merchandising_check_done"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_unique_branches_merchandising_check_done"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_branches_merchandising_check_done_physically"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_branches_merchandising_check_done_online"
                                  ? "yellow_clr"
                                  : head ==
                                    "no_of_open_tickets_marketing_actionables"
                                  ? "yellow_ligt"
                                  : head == "no_of_open_tickets_BM_actionables"
                                  ? "yellow_ligt"
                                  : "inv_clr"
                                : props.selected == 9
                                ? head
                                    .replace(/_/g, " ")
                                    .split(" ")
                                    .map(capitalize)
                                    .join(" ") ===
                                  "2(a) Number Of Large Poster Frames In The Branch Other Than The Mandatory Poster Frames As Covered In Point No. 1."
                                  ? "grn_lyt"
                                  : head
                                      .replace(/_/g, " ")
                                      .split(" ")
                                      .map(capitalize)
                                      .join(" ") ===
                                    "2(a) Does The Branch Have Digital Poster Frame Are Available In The Branch (mention Count)"
                                  ? "grn_lyt"
                                  : ""
                                : ""
                            }`}
                          >
                            {console.log("head: header", head)}
                            {head
                              .replace(/_/g, " ")
                              .split(" ")
                              .map(capitalize)
                              .join(" ")}
                          </th>
                        ))
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {props.list
                    ? props.list.map((rowData, index) => (
                        <tr>
                          <td key={index}>{index + 1}</td>
                          {props.header
                            ? props.header.map((head) =>
                                head === "audit_report" ? (
                                  <td key={head}>
                                    <a
                                      href={`${props.baseURL}${rowData[head]}`}
                                      target="_blank"
                                    >
                                      {rowData[head]}
                                    </a>
                                  </td>
                                ) : (
                                  <td key={head}>{rowData[head] ?? "NA"}</td>
                                  // <td key={head} className={rowData[head] && head == 'branch_not_visited_since_days' && rowData[head].split(' ', 1) >= 7 && 'red_bg_row'} >
                                  //   {rowData[head] ?? "NA"}
                                  // </td>
                                )
                              )
                            : null}
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            )}
          </div>
          <div className="bottom">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => props.handleAuditInfo("prev", props.offset - 1)}
            >
              <i className="fas fa-chevron-circle-left"></i>
            </button>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn btn-primary"
              table="table-to-xls"
              filename="Report"
              sheet="tablexls"
              buttonText="Download"
            />
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => {
                props.handleAuditInfo("next", props.offset + 1);
              }}
            >
              <i className="fas fa-chevron-circle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import apiEndPoints from "../../utils/apiEndPoints";
import { apiCall, successToast } from "../../utils/httpClient";
import BranchComponent from "./component/BranchComponent";

export default function Branch(props) {
  const [branch, setbranch] = useState([]);
  const [message, setmessage] = useState();

  useEffect(() => {
    branchDetail();
  }, []);

  const branchDetail = async () => {
    const params = { branch_id: props.match.params.id };
    const response = await apiCall("POST", apiEndPoints.BRANCH, params);
    console.log("the responseBRANCHBRANCH=  == = =>", response?.data?.data);
    if (response.status === 200) {
      setbranch({ ...response.data.data, city_id: response.data.data.city });
    }
  };

  const UpdateBranch = async () => {
    const params = { ...branch, city: branch.city_id }
    console.log("params",params);
    const response = await apiCall("POST", apiEndPoints.UPDATE_BRANCH, params);
    console.log(response, "response");
    if (response.status == 200) {
      setmessage(response.data.message);
      successToast(response.data.message);
    }
  };
  console.log("branch", branch);
  return (
    <BranchComponent
      branch={branch}
      setbranch={setbranch}
      UpdateBranch={UpdateBranch}
      message={message}
    />
  );
}

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import React, { useState, useRef, useEffect } from "react";
export default function ExcelExport(props) {
     const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
     const fileExtension = ".xlsx";


     const SetData = (csvData) => {
          let jsonList = [];

          csvData?.forEach(elementNH => {
               elementNH.rphArray.forEach((elementRPH)=>{
                    let jsonData = {};
                    jsonData['RPH Name'] = elementRPH.name;
                    jsonData['SRPM Name'] = "";
                    jsonData['RPM Name'] = "";
                    jsonData['Complete'] = "";
                    jsonData['SRPM'] = "";
                    jsonData["RPH"] = "";
                    jsonData["Not Complete"] = "";
                    jsonData['Grand Totel'] = "";
                  
                    jsonList.push(jsonData);

                    elementRPH.srpmArray.forEach((elementSRPM) => {
                         jsonData = {};
                         jsonData['RPH Name'] = "";
                         jsonData['SRPM Name'] = elementSRPM.name;
                         jsonData['RPM Name'] = "";
                         jsonData['Complete'] = "";
                         jsonData['SRPM'] = "";
                         jsonData["RPH"] = "";
                         jsonData["Not Complete"] = "";
                         jsonData['Grand Totel'] = "";
                       
                         jsonList.push(jsonData);
                     
                         var totalAuditCount=0;
                         var totalSRPMCount=0;
                         var totalRPHCount=0;
                         var totalNotCount=0;
                         var totalGrandTotal=0;
                         elementSRPM.rpmArray.forEach((elementRPM) => {
                              let jsonData = {};
                              jsonData['RPH Name'] = "";
                              jsonData['SRPM Name'] ="";
                              jsonData['RPM Name'] = elementRPM.name;
                              jsonData['Complete'] = elementRPM.auditCount;
                              jsonData['SRPM'] = elementRPM.auditCountSRPM;
                              jsonData["RPH"] = elementRPM.auditCountRPH;
                              jsonData["Not Complete"] = elementRPM.branchCount-(elementRPM.auditCount + elementRPM.auditCountRPH + elementRPM.auditCountSRPM);
                              jsonData['Grand Totel'] = elementRPM.branchCount;
                            
                              jsonList.push(jsonData);
                           
                              totalAuditCount += elementRPM.auditCount;
                              totalSRPMCount += elementRPM.auditCountSRPM;
                              totalRPHCount += elementRPM.auditCountRPH;
                              totalNotCount += elementRPM.branchCount-(elementRPM.auditCount + elementRPM.auditCountRPH + elementRPM.auditCountSRPM);
                              totalGrandTotal += elementRPM.branchCount;
                         });

                         jsonData = {};
                         jsonData['RPH Name'] = "";
                         jsonData['SRPM Name'] =elementSRPM.name + " Total";
                         jsonData['RPM Name'] = "";
                         jsonData['Complete'] = totalAuditCount;
                         jsonData['SRPM'] =totalSRPMCount;
                         jsonData["RPH"] = totalRPHCount;
                         jsonData["Not Complete"] = totalNotCount;
                         jsonData['Grand Totel'] = totalGrandTotal;
                       
                         jsonList.push(jsonData);
                      

                    })
               })
              

          });
          const ws = XLSX.utils.json_to_sheet(jsonList);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, props.fileName + fileExtension);
        //  console.log("Csv Header", props.csvHeader);

     }
     function GetQuestionData(data) {

          if (data.question_type == "1") {
               return (<div>{data.yes_no} <br></br>Remark:  {data.remark}</div>)
          }
          else if (data.question_type == "2") {
               return (<div>{data.quality} <br></br>Remark: {data.remark}</div>)
          }


     }
     function AddQuestionHeader(jsonData, questionArray) {


          if (questionArray.length > 0) {
               for (let i = 0; i < questionArray.length; i++) {
                    if (questionArray[i].question_type == "1") {
                         jsonData[questionArray[i].audit_question] = questionArray[i].yes_no;

                    }
                    else if (questionArray[i].question_type == "2") {
                         jsonData[questionArray[i].audit_question] = questionArray[i].quality;

                    }
                    jsonData['Remark-' + questionArray[i].audit_question] = questionArray[i].remark;


               }

          }
     }



     const exportToCSV = (csvData, fileName) => {
          SetData(csvData);
         // console.log("Export Data", csvData, fileName);



     }
     return (
          <div>
               <button  className="btn btn-primary" onClick={(e) => exportToCSV(props.csvData, props.fileName)}>Export</button>
          </div>);

}


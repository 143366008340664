import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import Alert from "../../../components/Alert";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";
import apiEndPoints from "../../../utils/apiEndPoints";
export default function BranchComponent(props) {
  const { branch, setbranch, UpdateBranch, message } = props;
  console.log("branch city id ", branch?.city_id);
  const [cityList, setcityList] = useState([]);
  var CityName = branch.city;
  var selected = branch.city === CityName ? true : false;
  const CityList = async () => {
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = { type: 1 };
    const response = await apiCall("POST", apiEndPoints.City_List, params);
    if (response.status === 200) {
      setcityList(response.data.data);
      console.log("response of city list", response?.data?.data);
    }
  };
  useEffect(() => {
    CityList();
    DisplayCityList();
  }, []);
  const DisplayCityList = async () => {
    CityName = console.log("cityname", CityName);
  };

  return (
    <div className="container-fluid">
      {message && <Alert message={message} />}
      {!branch && <h2>Loading...</h2>}
      <form className="form theme-form">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Branch Code</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Branch Name"
                    value={branch.branch_code}
                    onChange={(e) =>
                      setbranch({ ...branch, branch_code: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Branch Name</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Branch Name"
                    value={branch.branch_name}
                    onChange={(e) =>
                      setbranch({ ...branch, branch_name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group row mb-2">
                <label className="col-sm-3 col-form-label">
                  Branch Category
                </label>
                <div className="col-sm-9">
                  <select
                    id=""
                    className="form-control"
                    value={branch.branch_category}
                    onChange={(e) =>
                      setbranch({
                        ...branch,
                        branch_category: e.target.value,
                      })
                    }
                  >
                    {console.log(
                      "branch category data==>",
                      branch?.branch_category
                    )}
                    <option value={"A"}>A</option>
                    <option value={"B"}>B</option>
                    <option value={"C"}>C</option>
                  </select>
                </div>
              </div>

              <div className="form-group row mb-2">
                <label className="col-sm-3 col-form-label">Insurance Com</label>
                <div className="col-sm-9">
                  <select
                    id=""
                    className="form-control"
                    value={branch.insurance_com}
                    onChange={(e) =>
                      setbranch({
                        ...branch,
                        insurance_com: e.target.value,
                      })
                    }
                  >
                    {console.log(
                      "branch insurance_com data==>",
                      branch?.insurance_com
                    )}
                    <option value={"ABSLI"}>ABSLI</option>
                    <option value={"HLIC"}>HLIC</option>
                    <option value={"TATA"}>TATA</option>
                  </select>
                </div>
              </div>
              {/* <div className="form-group row mb-2">
                <label className="col-sm-3 col-form-label">Branch Id</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Branch Id"
                    value={branch.branch_id}
                    onChange={(e) =>
                      setbranch({
                        ...branch,
                        branch_id: e.target.value,
                      })
                    }
                  />
                </div>
              </div> */}

              {/* city  */}
              <div className="form-group row mt-2">
                <label className="col-sm-3 col-form-label">City</label>
                <div className="col-sm-9">
                  <select
                    id=""
                    className="form-control"
                    value={branch.city_id}
                    onChange={(e) =>
                      setbranch({ ...branch, city_id: e.target.value })
                    }
                  >
                    {cityList.map((value) => (
                      <option
                        value={value.city_id}
                        {...(branch.city_id == value.city_id ? "selected" : "")}
                      >
                        {value.city_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* state */}

              <div className="form-group row mt-2">
                <label className="col-sm-3 col-form-label">
                  Geographical State
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="State"
                    value={branch.state}
                    onChange={(e) =>
                      setbranch({
                        ...branch,
                        state: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* Rbi category */}

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RBI Class</label>
                <div className="col-sm-9">
                  <select
                    id=""
                    className="form-control"
                    value={branch.rbi_class}
                    onChange={(e) =>
                      setbranch({
                        ...branch,
                        rbi_class: e.target.value,
                      })
                    }
                  >
                    {/* {console.log("branch rbi_class data==>", branch?.rbi_class)} */}
                    <option value={"Local"}>Local</option>
                    <option value={"Metropolitan"}>Metropolitan</option>
                    <option value={"Urban"}>Urban</option>
                  </select>
                </div>
              </div>

              {/* branch manager starts */}

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Branch Manager
                </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Branch Manager"
                    value={branch.branch_manager}
                    onChange={(e) =>
                      setbranch({ ...branch, branch_manager: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* cluster starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Cluster</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Cluster"
                    value={branch.cluster}
                    onChange={(e) =>
                      setbranch({ ...branch, cluster: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* cluster head starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Cluster Head</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Cluster Head"
                    value={branch.cluster_head}
                    onChange={(e) =>
                      setbranch({ ...branch, cluster_head: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* zone starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Zone</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Zone"
                    value={branch.zone}
                    onChange={(e) =>
                      setbranch({ ...branch, zone: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* zonal head starts */}

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Zonal Head</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Zone Head"
                    value={branch.zonal_head}
                    onChange={(e) =>
                      setbranch({ ...branch, zonal_head: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* circle starts */}

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Circle</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Circle"
                    value={branch.circle}
                    onChange={(e) =>
                      setbranch({ ...branch, circle: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* cirlce head starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Circle Head</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Circle Head"
                    value={branch.circle_head}
                    onChange={(e) =>
                      setbranch({ ...branch, circle_head: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* bbh region */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">BBH Region</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="BBH Region"
                    value={branch.bbh_region}
                    onChange={(e) =>
                      setbranch({ ...branch, bbh_region: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* BBH Head starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">BBH Head</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="BBH Head"
                    value={branch.bbh_head}
                    onChange={(e) =>
                      setbranch({ ...branch, bbh_head: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* GH NAme  */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">GH Name</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="GH Name"
                    value={branch.group_head}
                    onChange={(e) =>
                      setbranch({ ...branch, group_head: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* NH Name starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">NH Name</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="NH Name"
                    value={branch.head_rm_name}
                    onChange={(e) =>
                      setbranch({ ...branch, head_rm_name: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* NH Mail Id */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">NH Mail ID</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" NH Mail ID"
                    value={branch.head_rm_mail}
                    onChange={(e) =>
                      setbranch({ ...branch, head_rm_mail: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* Nh Phone  */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">NH Phone</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" NH Phone"
                    value={branch.head_rm_phone}
                    onChange={(e) =>
                      setbranch({ ...branch, head_rm_phone: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* RPH name */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RPH Name</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" RPH Name"
                    value={branch.ah_name}
                    onChange={(e) =>
                      setbranch({ ...branch, ah_name: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* RPH mail ID */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RPH Mail ID </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" RPH Mail ID "
                    value={branch.ah_mail}
                    onChange={(e) =>
                      setbranch({ ...branch, ah_mail: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* RPH Phone */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RPH Phone </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" RPH Phone "
                    value={branch.ah_phone}
                    onChange={(e) =>
                      setbranch({ ...branch, ah_phone: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* SRPM name  */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">SRPM Name </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" SRPM Name"
                    value={branch.rmh_name}
                    onChange={(e) =>
                      setbranch({ ...branch, rmh_name: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* SRPM employee  */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">SRPM Email </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" SRPM Email "
                    value={branch.rmh_mail}
                    onChange={(e) =>
                      setbranch({ ...branch, rmh_mail: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* SRPM Phone  */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">SRPM Phone </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" SRPM Phone "
                    value={branch.rmh_phone}
                    onChange={(e) =>
                      setbranch({ ...branch, rmh_phone: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* rpm name */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RPM Name </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" RPM Name "
                    value={branch.rmm_name}
                    onChange={(e) =>
                      setbranch({ ...branch, rmm_name: e.target.value })
                    }
                  />
                </div>
              </div>

              {/* rpm Mail id */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RPM Email Id </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" RPM Mail Id "
                    value={branch.rmm_email}
                    onChange={(e) =>
                      setbranch({ ...branch, rmm_email: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* rpm Phone */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">RPM Phone </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" RPM Phone"
                    value={branch.rmm_phone}
                    onChange={(e) =>
                      setbranch({ ...branch, rmm_phone: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* branch type  */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"> Branch Type</label>
                <div className="col-sm-9">
                  <select
                    id=""
                    className="form-control"
                    value={branch.branch_type}
                    onChange={(e) =>
                      setbranch({
                        ...branch,
                        branch_type: Number(e.target.value),
                      })
                    }
                  >
                    {/* {console.log("branch type edit ", branch.branch_type)} */}
                    <option value={0}>Old Branch</option>
                    <option value={1}>New Branch</option>
                  </select>
                </div>
              </div>

              {/* latitude starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Latitude </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" latitude"
                    value={branch.latitude}
                    onChange={(e) =>
                      setbranch({ ...branch, latitude: e.target.value })
                    }
                  />
                </div>
              </div>
              {/* logitude starts */}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Longitude </label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=" Longitude"
                    value={branch.longitude}
                    onChange={(e) =>
                      setbranch({ ...branch, longitude: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Branch Address
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    rows="5"
                    cols="5"
                    placeholder="Address"
                    value={branch.branch_address}
                    onChange={(e) =>
                      setbranch({ ...branch, branch_address: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="col-sm-9 offset-sm-3">
            <button
              className="btn btn-primary mr-1"
              type="button"
              onClick={() => UpdateBranch()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

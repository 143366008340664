import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import {
  apiCall,
  setDefaultHeader,
  successToast,
} from "../../../utils/httpClient";
import apiEndPoints from "../../../utils/apiEndPoints";

const Addbranch = () => {
  const [csvScreen, setCsvScreen] = useState(false);
  const [csvFile, setCsvFile] = useState({
    csv_file: "",
  });
  const [formErr, setFormErr] = useState({});
  const [resp, setResp] = useState(false);
  const [errResp, setErrResp] = useState(false);
  const [errRespMessage, setErrRespmessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [inputData, setInputData] = useState({
    branch_code: "",
    branch_name: "",
    branch_category: "A",
    insurance_com: "ABSLI",
    city_id: "",
    state: "",
    rbi_class: "Local",
    branch_manager: "",
    bm_emp_code: "",
    cluster: "",
    cluster_head: "",
    zone: "",
    zonal_head: "",
    circle: "",
    circle_head: "",
    bbh_region: "",
    bbh_head: "",
    group_head: "",
    head_rm_name: "",
    head_rm_mail: "",
    head_rm_phone: "",
    ah_name: "",
    ah_mail: "",
    ah_phone: "",
    rmh_name: "",
    rmh_email: "",
    rmh_phone: "",
    rmm_name: "",
    rmm_email: "",
    rmm_phone: "",
    latitude: "",
    longitude: "",
    branch_address: "",
    pin_code: "",
    branch_type: 0,
  });
  // console.log("inputDataaaaaaaaa city Id ", inputData?.city)
  const [cityList, setcityList] = useState([]);
  const [CityserviceListShow, setCityServiceListShow] = useState(false);
  const [filterCityList, setFilterCityList] = useState([]);
  const [Loader, setLoader] = useState(false);

  const handleCityListInput = (e) => {
    console.log("e", e);
    setCityServiceListShow(true);
    var City_List = cityList.filter((curElem) => {
      if (e == "") {
        return curElem;
      } else if (
        curElem.city_name
          .toString()
          .toLowerCase()
          .includes(e.toString().toLowerCase())
      ) {
        return curElem;
      }
    });
    console.log("curElem", City_List);
    setFilterCityList(City_List);
  };

  function handleSelectCItyService(item) {
    console.log("itemmmmmmmm", item);
    document.getElementById("serviceCitySearch").value = item;
    setCityServiceListShow(false);
    // setEmployeeList([]);
  }

  var CityName = [];
  const CityList = async () => {
    setLoader(true);
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);
    const params = { type: 1 };
    const response = await apiCall("POST", apiEndPoints.City_List, params);
    // console.log("GETCITYLISTDATA data  citi list ===>", response);
    if (response.status === 200) {
      setcityList(response.data.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const DisplayCityList = async () => {
    CityName = console.log("cityname", CityName);
  };

  useEffect(() => {
    CityList();
    DisplayCityList();
  }, []);

  
  const branchDataInsert = async () => {
    if (validateForm()) {
      const params = { ...inputData, city: inputData.city_id };
      try {
        const userToken = localStorage.getItem("AuthToken");
        setDefaultHeader("token", userToken);
        const response = await apiCall(
          "POST",
          apiEndPoints.UPDATE_BRANCH,
          params
        );
        if (response.status === 200) {
          successToast(response?.data?.message);
          window.location.href = "/dashboard/branchlist";
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  function validateForm() {
    let formErrors = {};
    let formIsValid = true;
    const emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    // Validate branch code
    if (!inputData.branch_code) {
      formIsValid = false;
      formErrors["branch_code"] = "Enter branch Code";
    }

    // Validate name
    if (!inputData.branch_name) {
      formIsValid = false;
      formErrors["branch_name"] = "Enter branch name";
    }

    if (!inputData.city_id) {
      formIsValid = false;
      formErrors["city_id"] = "Enter city";
    }

    setFormErrors(formErrors);
    return formIsValid;
  }

  return (
    <>
      <Breadcrumb title="Branch" parent="Add Branch" />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            {csvScreen == false ? (
              <div>
                {/* <button className="add-emp-btn" onClick={(e) => setCsvScreen(true)}>
                Upload CSV
              </button> */}
                <form className="form theme-form">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Branch Code
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Branch Code"
                              value={inputData.branch_code}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_code: e.target.value,
                                })
                              }
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                              }}
                            >
                              {!inputData.branch_code &&
                              formErrors &&
                              formErrors.branch_code
                                ? formErrors.branch_code
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Branch Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Branch Name"
                              value={inputData.branch_name}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_name: e.target.value,
                                })
                              }
                            />
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                              }}
                            >
                              {!inputData.branch_name &&
                              formErrors &&
                              formErrors.branch_name
                                ? formErrors.branch_name
                                : ""}
                            </span>
                          </div>
                        </div>
                        {/* <div className="form-group row mb-2" >
                        <label className="col-sm-3 col-form-label">Branch Id</label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Branch Id"
                            value={inputData.branch_id}
                            onChange={(e) =>
                              setInputData({
                                ...inputData,
                                branch_id: e.target.value,
                              })
                            }
                          />
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",

                            }}
                          >
                            {!inputData.branch_code &&
                              formErrors &&
                              formErrors.branch_code
                              ? formErrors.branch_code
                              : ""}
                          </span>
                        </div>
                      </div> */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Branch Category
                          </label>
                          <div className="col-sm-9">
                            <select
                              id=""
                              className="form-control"
                              value={inputData.branch_category}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_category: e.target.value,
                                })
                              }
                            >
                              <option value={"A"}>A</option>
                              <option value={"B"}>B</option>
                              <option value={"C"}>C</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Insurance Com
                          </label>
                          <div className="col-sm-9">
                            <select
                              id=""
                              className="form-control"
                              value={inputData.insurance_com}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  insurance_com: e.target.value,
                                })
                              }
                            >
                              <option value={"ABSLI"}>ABSLI</option>
                              <option value={"HLIC"}>HLIC</option>
                              <option value={"TATA"}>TATA</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            City
                          </label>
                          <div className="col-sm-9">
                            <div className="abs">
                              <input
                                type="text"
                                className="form-control"
                                id="serviceCitySearch"
                                // value={inputData.city}
                                onChange={(e) =>
                                  handleCityListInput(e.target.value)
                                }
                                placeholder={
                                  Loader == true
                                    ? "Loading.........."
                                    : "Type to Search City"
                                }
                                autoComplete="off"
                              />
                            </div>
                            <div>
                              <div>
                                {/* {console.log("CityList", cityList)} */}
                                {CityserviceListShow &&
                                cityList &&
                                cityList.length > 0 ? (
                                  <ul className="emp_list_menu">
                                    {filterCityList.map((item, i) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            setInputData({
                                              ...inputData,
                                              city_id: item.city_id,
                                            });
                                            handleSelectCItyService(
                                              item.city_name
                                            );
                                          }}
                                        >
                                          <div
                                            className="emp_li"
                                            style={
                                              {
                                                // display: "flex",
                                                // justifyContent: "center",
                                              }
                                            }
                                          >
                                            <div
                                              style={{
                                                width: "15%",
                                                marginLeft: "5px",
                                              }}
                                              className="empl_list"
                                            >
                                              <span>{item.city_name}</span>
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    }, [])}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <span
                              style={{
                                color: "red",
                                fontSize: "10px",
                              }}
                            >
                              {!inputData.city_id &&
                              formErrors &&
                              formErrors.city_id
                                ? formErrors.city_id
                                : ""}
                            </span>
                          </div>
                        </div>

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Geographical State :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Geographical State"
                              value={inputData.state}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  state: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            RBI Class :
                          </label>
                          <div className="col-sm-9">
                            <select
                              id=""
                              className="form-control"
                              value={inputData.rbi_class}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rbi_class: e.target.value,
                                })
                              }
                            >
                              <option value={"Local"}>Local</option>
                              <option value={"Metropolitan"}>
                                Metropolitan
                              </option>
                              <option value={"Urban"}>Urban</option>
                            </select>
                          </div>
                        </div>

                        {/* branch manager starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Branch Manager :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Branch Manager"
                              value={inputData.branch_manager}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_manager: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* branch manager ends */}

                        {/* branch manager employee code starts */}
                        <div className="form-group row mb-1">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            Branch Manager employee code :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Branch Manager employee code "
                              value={inputData.bm_emp_code}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  bm_emp_code: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* branch manager employee ends  */}

                        {/* branch manager mobile number starts */}
                        {/* <div className="form-group row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Manager Number :
                        </label>
                        <div className="col-sm-9">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Manager Number"
                            value={inputData.rim}
                            onChange={(e) =>
                              setInputData({ ...inputData, rim: e.target.value })
                            }
                          />
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",
                              // marginLeft: "10px",
                            }}
                          >
                            {!inputData.rim &&
                              formErrors &&
                              formErrors.rim
                              ? formErrors.rim
                              : ""}
                          </span>
                        </div>
                      </div> */}
                        {/* branch manager number ends */}

                        {/* cluster starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Cluster :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Cluster"
                              value={inputData.cluster}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  cluster: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* cluster head starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Cluster Head:
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Cluster Head"
                              value={inputData.cluster_head}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  cluster_head: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* zone starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Zone :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Zone"
                              value={inputData.zone}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  zone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Zonal Head
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Zonal Head"
                              value={inputData.zonal_head}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  zonal_head: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* circle starts */}
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Circle
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Circle"
                              value={inputData.circle}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  circle: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* circle head starts */}
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Circle Head
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Circle Head"
                              value={inputData.circle_head}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  circle_head: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* BBH Region starts */}

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            BBH Region :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="BBH Region"
                              value={inputData.bbh_region}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  bbh_region: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* bbh head starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            BBH Head :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="BBH Head"
                              value={inputData.bbh_head}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  bbh_head: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* GH Name starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            GH Name:
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="GH Name"
                              value={inputData.group_head}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  group_head: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* NH name */}

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            NH Name:
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="NH Name"
                              value={inputData.head_rm_name}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  head_rm_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* NH Mail */}

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            NH Mail:
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="NH Name"
                              value={inputData.head_rm_mail}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  head_rm_mail: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* NH Phone */}

                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            NH Phone:
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="NH Phone"
                              value={inputData.head_rm_phone}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  head_rm_phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* rph name */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            RPH Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="RPH Name"
                              value={inputData.ah_name}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  ah_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* rph Mail */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            RPH Mail
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="RPH Mail"
                              value={inputData.ah_mail}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  ah_mail: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* RPH Phone */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            RPH Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="RPH Phone"
                              value={inputData.ah_phone}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  ah_phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* SRPM name */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            SRPM Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="SRPM Name"
                              value={inputData.rmh_name}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rmh_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* SRPM mail */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            SRPM Mail
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="SRPM Mail"
                              value={inputData.rmh_email}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rmh_email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* SRPM Phone */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            SRPM Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="SRPM Phone"
                              value={inputData.rmh_phone}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rmh_phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* RPM Name */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            RPM Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="RPM Name"
                              value={inputData.rmm_name}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rmm_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* RPM Mail */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            RPM Mail
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="RPM Mail"
                              value={inputData.rmm_email}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rmm_email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* RPM Phone */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            RPM Phone
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="RPM Phone"
                              value={inputData.rmm_phone}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  rmm_phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* braqnch type */}
                        {/* branch type  */}
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            Branch Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              id=""
                              className="form-control"
                              value={inputData.branch_type}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_type: Number(e.target.value),
                                })
                              }
                            >
                              <option value={0}>Old Branch</option>
                              <option value={1}>New Branch</option>
                            </select>
                          </div>
                        </div>

                        {/* Latitude starts */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            Latitude :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="latitude"
                              value={inputData.latitude}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  latitude: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* logititude */}
                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            longitude :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="latitude"
                              value={inputData.longitude}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  longitude: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row mb-2">
                          <label className="col-sm-3 col-form-label">
                            {" "}
                            Pincode :
                          </label>
                          <div className="col-sm-9">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Pincode"
                              value={inputData.pin_code}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  pin_code: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row mb-3 mt-2">
                          <label className="col-sm-3 col-form-label">
                            Branch Address :
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              className="form-control"
                              rows="5"
                              cols="5"
                              placeholder="Branch Adress"
                              value={inputData.branch_address}
                              onChange={(e) =>
                                setInputData({
                                  ...inputData,
                                  branch_address: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-sm-9 offset-sm-3">
                      <button
                        className="btn btn-primary mr-1"
                        type="button"
                        onClick={() => branchDataInsert()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>{" "}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Addbranch;

import React, { useState } from "react";
import "./style.css";
import moment from "moment";
import { Table } from "react-bootstrap";

import { BASE_URL, S3_URL } from '../../../utils/constants.js';
export default function ReportDataPdf(props) {

     const { list } = props;
     const [toggleFrom, settoggleFrom] = useState(false);
     const [toggleto, settoggleto] = useState(false);


     let auditRecord = {};
     // if (list) {
     //      auditRecord = list;
     // }
     // else {

     // }
     const gridCols = [[], []];
     let imageCount = 0;
     function getImageTimeStamp(imageName) {
          let imageNameArray = imageName.split("_");
          let timeStamp = imageNameArray[imageNameArray.length - 1];
          timeStamp = timeStamp.substring(0, timeStamp.length - 4);
          return timeStamp;
     }
     var loadingCount = 0;
     const LoadImage = (index, imageName) => {
          loadingCount++;
          index = index.substring(4, index.length);
          console.log(loadingCount, imageCount);
          if (loadingCount === imageCount-5) {
               console.log(loadingCount, imageCount);
               props.setPrintWQFlag(true);

          }



     };

     const ErrorLoadImage = (index, imageName, auditId) => {


     };



     function GetImageTag(imageName, auditId, auditType, lat, long, imageDate) {
         // console.log(lat, long);
          let imageCountLable = "";
          if (imageName != "" && imageName != undefined) {
               if (auditType == "Online") {
                    if (imageName.indexOf("VirtualReviewImage") >= 0) {
                         imageCount++;
                         imageCountLable = 'img_' + imageCount;

                         return <figure id={'imageTag_' + imageCount} key={'imageTag_' + imageCount}><img src={S3_URL + imageName} className="onlineImageWQ" onLoad={() => LoadImage(imageCountLable, imageName)} onError={ErrorLoadImage(imageCountLable, imageName, auditId)} />
                              <figcaption className="figcaptionWQ">{lat},{long}<br></br>{getImageTimeStamp(imageName)}</figcaption>
                         </figure>

                    }
                    else {
                         imageCount++;
                         imageCountLable = 'img_' + imageCount;

                         return <figure id={'imageTag_' + imageCount} key={'imageTag_' + imageCount}><img src={BASE_URL + 'upload/question/' + imageName} onLoad={() => LoadImage(imageCountLable, imageName)} onError={ErrorLoadImage(imageCountLable, imageName, auditId)} /><figcaption className="figcaptionWQ">{lat},{long}<br></br>{imageDate}</figcaption></figure>

                    }

               }
               else {
                    imageCount++;
                    imageCountLable = 'img_' + imageCount;

                    return <figure id={'imageTag_' + imageCount} key={'imageTag_' + imageCount}><img src={BASE_URL + 'upload/question/' + imageName} onLoad={() => LoadImage(imageCountLable, imageName)} onError={ErrorLoadImage(imageCountLable, imageName, auditId)} /></figure>
               }
          }

     }


     function NoFlagRecord(auditRecord) {
          var noFlag = true;
          auditRecord.questionArray.forEach((questionRow) => {
               if (questionRow.yes_no == "YES") {
                    noFlag = false;
               }
          });
          return noFlag;
     }
     function GenerateRowArray(noFlag) {
          var contentRows = [];
          props.list?.forEach((auditRecord, index) => {
               var contentRowObject = {};
               if (NoFlagRecord(auditRecord) == noFlag) {
                    contentRowObject.reviewId = auditRecord.audit_id;
                    contentRowObject.branchName = auditRecord.branch_name;
                    contentRowObject.insurar = auditRecord.insurance_com;
                    contentRowObject.auditType = auditRecord.audit_type;
                    contentRowObject.reviewBy = auditRecord?.audit_date + '  Review By  ';
                    auditRecord?.audit_type == "Physical" ? contentRowObject.reviewBy += auditRecord?.auditorName + ' (' + auditRecord?.role_name + ')'
                         : contentRowObject.reviewBy += auditRecord?.branch_manager + ' (BM)';
                    contentRowObject.quality = auditRecord.questionArray[2]?.question_type == 1 ? auditRecord.questionArray[2]?.yes_no : auditRecord.questionArray[2]?.quality;
                    contentRowObject.rowType = 'HEADER';
                    contentRowObject.images = [];

                    contentRows.push(contentRowObject);
                    var contentRowQuestions = [];
                    auditRecord.questionArray.forEach((questionRow) => {

                         if (questionRow.yes_no == "YES") {

                              const reviewImages = questionRow.image_capture.split(",");

                              reviewImages.forEach(imageObj => {
                                   contentRowObject = {};
                                   contentRowObject.answer = questionRow.yes_no;
                                   contentRowObject.auditType = auditRecord.audit_type;
                                   contentRowObject.reviewId = auditRecord.audit_id;
                                   contentRowObject.lat = auditRecord.audit_latitude;
                                   contentRowObject.long = auditRecord.audit_longitude;

                                   if (auditRecord.audit_type == "Online") {
                                        contentRowObject.imageName = auditRecord.images.filter(r => r.image_data == imageObj);
                                   }
                                   else {
                                        contentRowObject.imageName = imageObj;
                                   }


                                   if (questionRow.question_id == 567) {
                                        contentRowObject.imageTitle = "1) Poster displayed?";
                                   }
                                   else if (questionRow.question_id == 568) {
                                        contentRowObject.imageTitle = "2) Standee displayed?";
                                   }
                                   else if (questionRow.question_id == 571) {
                                        contentRowObject.imageTitle = "3) Content non co-branded?";
                                   }
                                   contentRowQuestions.push(contentRowObject);

                              });

                         }



                    });
                    GenerateRowArraySetImages(contentRowQuestions, contentRows, 3, auditRecord.audit_id);

               }
          });

          console.log(contentRows);
          return contentRows;
     }

     function GenerateRowArraySetImages(contentRowQuestions, contentRows, chunkSize, audit_id) {

          for (let i = 0; i < contentRowQuestions.length; i += chunkSize) {
               const chunk = contentRowQuestions.slice(i, i + chunkSize);
               var contentRowObject = {};
               contentRowObject.rowType = 'CONTENT';
               contentRowObject.reviewId = audit_id;
               contentRowObject.images = chunk;
               contentRows.push(contentRowObject);
          }
     }


     function GenerateRowArrayImageColHeading(images, divId) {

          var divArray = [];
          images.forEach((imageObj, index) => {

               divArray.push(<div className="col-4" key={divId + "_" + index}>

                    <div className="row">
                         <div className="col tdBorder">
                              {
                                   imageObj.auditType == "Online" ?
                                        GetImageTag(imageObj?.imageName[0]?.image_data, imageObj.reviewId, imageObj.auditType, imageObj.lat, imageObj.long, imageObj?.imageName[0]?.imageDateTime)
                                        :
                                        GetImageTag(imageObj.imageName, imageObj.reviewId, imageObj.auditType, "", "", '')

                              }

                         </div>
                    </div>
               </div>)

          })
          return divArray;
     }

     function GenerateRow() {
          var contentRow = GenerateRowArray(false);

          var trArray = [];
          var pageBrackFlag = false;
          var contentCount = 0;
          contentRow.forEach((auditRecord, index) => {
               var style = "";

               style = { pageBreakAfter: "avoid" }

               if (auditRecord.rowType == "HEADER") {
                    pageBrackFlag = false;

                    trArray.push(<div className="row" style={style} key={'questionTd_' + auditRecord.reviewId + "_audit_details_table"}>
                         <div className="col tdBorder">
                              <strong>Review Id:</strong>{auditRecord.reviewId}
                              <strong> Branch Name:</strong>{auditRecord?.branchName}
                              <strong>  Insurer:</strong>{auditRecord?.insurar}
                              <strong>  Review Type:</strong> {auditRecord?.auditType}

                              <strong>  Review date:</strong> {auditRecord?.reviewBy}
                         </div>

                    </div>)
               }
               else {
                    contentCount++;
                    if (contentCount == 4) {
                         contentCount = 0;
                         style = { pageBreakAfter: "always" }
                    }
                    trArray.push(<div className="row  tdBorder" style={style} key={'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content"}>

                         {GenerateRowArrayImageColHeading(auditRecord.images, 'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content")}

                    </div>
                    )
               }

          })

          contentRow = GenerateRowArray(true);

          contentRow.forEach((auditRecord, index) => {
               if (auditRecord.rowType == "HEADER") {
                    pageBrackFlag = false;

                    trArray.push(<div className="row" key={'questionTd_' + auditRecord.reviewId + "_audit_details_table"}>
                         <div className="col tdBorder">
                              <strong>Review Id:</strong>{auditRecord.reviewId}
                              <strong> Branch Name:</strong>{auditRecord?.branchName}
                              <strong>  Insurar:</strong>{auditRecord?.insurar}
                              <strong>  Review Type:</strong> {auditRecord?.auditType}

                              <strong>  Review date:</strong> {auditRecord?.reviewBy}
                         </div>
                        
                    </div>)
               }
               else {

                    trArray.push(<div className="row  tdBorder" key={'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content"}>

                         {GenerateRowArrayImageColHeading(auditRecord.images, 'questionTd_' + auditRecord.reviewId + "_" + index + "_audit_details_content")}

                    </div>
                    )
               }

          })
          return trArray;
     }
     
     return (
          
          <div style={{ margin: '10px',display:'none' }} id='reportDataWQInPdf'>
               {
               
                props.printWQFlagStart == true ?
               
                    <div>
                         <h4>Branch Review Report</h4>
                         {GenerateRow()}
                    </div>
                    :
                    null
                }
          </div>
          
     );
}

import exportFromJSON from "export-from-json";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import apiEndPoints from "../../utils/apiEndPoints";
import { apiCall, setDefaultHeader } from "../../utils/httpClient";
import ReportDashboardComponent from "./component/ReportDashboardComponent";

export default function ReportDashboard() {
  //let from_date =new Date();
  // from_date.setDate(from_date.getDate()-15);
  const today = new Date();
  let from_date = new Date(today.getFullYear(), today.getMonth(), today.getDate() );


  const [selected, setselected] = useState(8);
  const [list, setlist] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [header, setheader] = useState();
  var [offset, setoffset] = useState(0);
  const [from, setfrom] = useState(from_date);
  const [to, setto] = useState(new Date());
  const typeRef = useRef(8);
  const [baseURL, setbaseURL] = useState();
  const [search, setsearch] = useState("");
  const [searchArray, setsearchArray] = useState();
  const [branchId, setBranchId] = useState("");
  const [insurarId, setInsurarId] = useState("");
  const [auditStatus, setAuditStatus] = useState("3,5");

  const [city_id, setCityId] = useState("");
  const [state_id, setStateId] = useState("");
  const [region_id, setRegionId] = useState("");
  const [cluster_id, setClusterId] = useState("");
  const [circle_id, setCircleId] = useState("");
  const [auditorId, setAuditorId] = useState("");
  const [zone_id, setZoneId] = useState("");
  const [auditRecords, setAuditRecords] = useState("");
  const [reviewType, setReviewType] = useState("");

  const handleSelect = (id) => {
    setselected(id);
    typeRef.current = id;
    setisLoading(true);
    getAuditRecords("", 0);
  };
  
  const handleView = (id) => {
    // let path = `/ViewReport/` + id;
    // let history = useHistory();
    // history.push(path);
  }
  useEffect(() => {
    handleAuditInfo("", 0);
  }, []);

  const getAuditRecords = async (state, set) => {
    const userLoginData = JSON.parse(localStorage.getItem('userData'));
    const params = {
      type: typeRef.current,
      offset: set,
      from_date: moment(from).format("YYYY-MM-DD"),
      to_date: moment(to).format("YYYY-MM-DD"),
      emp_role_id: userLoginData.emp_role_id,
      branchId: branchId,
      insurarId: insurarId,
      auditStatus: auditStatus,
      emp_id: userLoginData.emp_id,
      city_id: city_id,
      state_id: state_id,
      region_id: region_id,
      cluster_id: cluster_id,
      circle_id: circle_id,
      zone_id: zone_id,
      auditorId: auditorId,
      reviewType:reviewType

    };
    const userToken = localStorage.getItem("AuthToken");
    await setDefaultHeader("token", userToken);

    var response = []
   
    var response = await apiCall(
      "POST",
      apiEndPoints.GETAUDITRECORDS,
      params
    );
   
    if (response.status === 200) {

      setAuditRecords(response);
     
      setheader(response.data.header);
     
    
      setisLoading(false);
    }
  }

  const handleAuditInfo = async (state, set) => {
    try {
      const userLoginData = JSON.parse(localStorage.getItem('userData'));

      setisLoading(true);
      const params = {
        type: typeRef.current,
        offset: set,
        from_date: moment(from).format("YYYY-MM-DD"),
        to_date: moment(to).format("YYYY-MM-DD"),
        emp_role_id: userLoginData.emp_role_id,
        branchId: branchId,
        insurarId: insurarId,
        auditStatus: auditStatus,
        emp_id: userLoginData.emp_id,
        city_id: city_id,
        state_id: state_id,
        region_id: region_id,
        cluster_id: cluster_id,
        circle_id: circle_id,
        zone_id: zone_id,
        auditorId: auditorId,
        reviewType:reviewType

      };

      const userToken = localStorage.getItem("AuthToken");
      await setDefaultHeader("token", userToken);

      var response = []
      params.from_date = moment(from_date).format("YYYY-MM-DD");
      var response = await apiCall(
        "POST",
        apiEndPoints.GETKPIDATA,
        params
      );
      if (response.status === 200) {

        setlist(response);
        getAuditRecords();
      
       
      
      }
      if (response.data.status === 201) {
        setlist();
        setheader();
      }
      if (response.data.status !== 201) {
        if (state === "next") {
          setoffset(set);
        }
        if (state === "prev") {
          if (offset > 0) setoffset(set);
        }
      }
    
    } catch (error) {
      setisLoading(false);
      
    }
  };
  return (
    <>
      <ReportDashboardComponent
        selected={selected}
        handleSelect={handleSelect}
        handleView={handleView}
        isLoading={isLoading}
        list={list}
        auditRecords={auditRecords}
        header={header}
        handleAuditInfo={handleAuditInfo}
        offset={offset}
        from={from}
        setfrom={setfrom}
        to={to}
        setto={setto}
        baseURL={baseURL}
        search={search}
        setsearch={setsearch}
        searchArray={searchArray}
        setsearchArray={setsearchArray}
        branchId={branchId}
        setBranchId={setBranchId}
        insurarId={insurarId}
        setInsurarId={setInsurarId}
        auditStatus={auditStatus}
        setAuditStatus={setAuditStatus}
        city_id={city_id}
        setCityId={setCityId}
        state_id={state_id}
        setStateId={setStateId}
        region_id={region_id}
        setRegionId={setRegionId}
        cluster_id={cluster_id}
        setClusterId={setClusterId}
        circle_id={circle_id}
        setCircleId={setCircleId}
        zone_id={zone_id}
        setZoneId={setZoneId}
        auditorId={auditorId}
        setAuditorId={setAuditorId}
        reviewType={reviewType}
        setReviewType={setReviewType}
      />
    </>
  );
}

import React, { useState, useEffect } from 'react'
import Breadcrumb from '../../../components/common/breadcrumb';
import apiEndPoints from '../../../utils/apiEndPoints';
import { apiCall, setDefaultHeader } from '../../../utils/httpClient';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Search from '../../../components/Search';
import { useToken } from 'react-bootstrap-typeahead'
import SearchHeader from '../../../components/common/header-component/searchHeader';
export default function QuestionList() {
    const [questionList, setquestionList] = useState()
    const [search, setsearch] = useState("")
    const [searchArray, setsearchArray] = useState()
    const QuestionList = async () => {
        try {
            const userToken = localStorage.getItem('AuthToken')
            console.log("userToken", userToken);
            await setDefaultHeader('token', userToken)
            const response = await apiCall('GET', apiEndPoints.QUESTION_LIST)
            console.log("response", response)
            if (response.status === 200) {
                setquestionList(response.data.data)
                setsearchArray(response.data.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        QuestionList()
    }, [])


    const HandleDelete = async (question_id) => {

        if (window.confirm("Are you sure want to delete the Question!")) {
            try {
                var param = {
                    question_id: question_id,
                }
                const userToken = localStorage.getItem('AuthToken')
                setDefaultHeader('token', userToken)
                const response = await apiCall('post', apiEndPoints.DELETE_QUESTION, param)
                console.log("res", response)
                if (response.status === 200) {
                    var a = [...questionList]
                    a.splice(a.indexOf(question_id), 1)
                    setquestionList(a)
                }
            } catch (error) {
                console.log(error)
            }

        }
    }
    const columns = [
        // {
        //     name: 'Title',
        //     selector: row => row.question_title,
        //     sortable: true
        // },
        {
            name: 'Question',
            selector: row => row.audit_question.substring(0, 100),
            sortable: true
        },
        {
            name: 'Branch Type',
            selector: row => row.type === 0 ? "Old Branch" : "New Branch",
            sortable: true
        },
        {
            name: 'Action',
            selector: row =>
                <div style={{ alignItems: "center" }}>
                    <Link to={`/dashboard/question/${row.question_id}`}>
                        <i class="fas fa-eye"></i>
                    </Link>
                    {/* <Link onClick={() => HandleDelete(row.question_id)} style={{ marginLeft: 10 }} >
                        <i class="fa fa-trash " aria-hidden="true" style={{ color: "red" }}></i>
                    </Link> */}
                </div>,
        }
    ];
    return (
        <>
            <Breadcrumb title="Question" parent="Question List" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div style={{ borderRadius: '20px', outline: '10px', border: '0.2px solid lightgray', width: '670px' }}>
                                    <Search search={search} setsearch={setsearch} title={"Search By Question Title"} />
                                </div>

                            </div>
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">

                                        <DataTable
                                            columns={columns}
                                            data={questionList && questionList.filter((data) => {
                                                // console.log(data,"Data")
                                                if (search === "")
                                                    return searchArray
                                                else if (data.audit_question.toLowerCase().includes(search.toLowerCase()))
                                                    return data
                                            })}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
